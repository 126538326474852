<template>
  <div class="enterprise">
    <div class="choose">
      <ul>
        <li
          v-for="(items, index) in choose"
          :key="index"
          :class="
            activeName == items.F_Id ? 'subselection_new' : 'subselection'
          "
          @click="jump(items)"
        >
          <span>{{ items.F_FullName }}</span>
        </li>
      </ul>
    </div>

    <div
      class="choose_center"
      v-loading="Loading"
      v-if="choose_list[0].child.length != 0"
    >
      <div>
        <div
          v-for="(items, indexs) in choose_list"
          :key="indexs"
          class="choose_li"
        >
          <div
            v-for="(i, indexi) in items.child"
            :key="indexi"
            class="Settle_in"
            @click="jumps(i)"
          >
            <img
              v-if="i.qytx.length > 0"
              :src="'http://114.117.195.108:3000' + i.qytx[0].url"
              class="enterprise_img"
              alt=""
            />
            <!-- <img
              v-if="i.qytx.length == 0"
              :src="i.imgRandomNumUrl"
              class="enterprise_img"
              alt=""
            /> -->
            <img
              v-if="i.qytx.length == 0"
              :src="
                require('../../../assets/image/random_qiye/' +
                  i.imgRandomNum +
                  '.jpg')
              "
              class="enterprise_img"
              alt=""
            />
            <p class="Settle_span">{{ i.qymc }}</p>
          </div>
        </div>
      </div>
    </div>
    <el-empty
      v-loading="Loading"
      v-if="choose_list[0].child.length == 0"
      description="暂无数据"
    ></el-empty>
  </div>
</template>

<script>
import { OrderService } from "../../../api/product";
export default {
  props: ["Enterpriselist"],
  data() {
    return {
      activeName: "1",
      choose: [
        {
          name: "全部",
          id: 1,
        },
        {
          name: "高新企业",
          id: 2,
        },
        {
          name: "高新企业",
          id: 3,
        },
        {
          name: "高新企业",
          id: 4,
        },
        {
          name: "高新企业",
          id: 5,
        },
        {
          name: "高新企业",
          id: 6,
        },
        {
          name: "高新企业",
          id: 7,
        },
        {
          name: "高新企业",
          id: 8,
        },
      ],
      choose_list: [
        {
          child: [],
        },
      ],
      result_list: [],
      Loading: false,
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight,
    };
  },
  mounted() {
    // console.log(this.screenWidth + '/' + this.screeHeight, '屏幕尺寸')
    this.getEnterpriselistAll();
    this.getqiyetypes();
    // this.choose_list[0].child =this.Enterpriselist
  },
  methods: {
    jump(e) {
      this.activeName = e.F_Id;

      if (e.F_Id == "1") {
        this.getEnterpriselistAll();
      } else if (e.F_Id != "1" && e.F_Id != "0") {
        this.getEnterpriselistType(e.F_Id);
      } else if (e.F_Id == "0") {
        this.result_list = [
          {
            fullName: "企业入驻",
          },
          {
            fullName: "",
          },
        ];
        sessionStorage.setItem("screening", JSON.stringify(this.result_list));
        var lists = {
          type: "全部",
        };
        this.$router.push({ name: "Qiye_resource", query: lists });
      }
    },
    // 获取企业列表
    getEnterpriselistAll() {
      this.Loading = true;

      OrderService.getEnterpriseTypeList("", 0, 12).then((res) => {
        let list = res.data.list;

        list.forEach((i, index) => {
          if (i.qytx != undefined) {
            i.qytx = i.qytx && JSON.parse(i.qytx);
          } else {
            i.qytx = [];
          }

          let imgRandomNum = Number(i.F_Id.slice(-1));
          if (imgRandomNum == 0) {
            imgRandomNum++;
          } else if (imgRandomNum > 8) {
            imgRandomNum--;
          }
          i.imgRandomNum = imgRandomNum;
          i.imgRandomNumUrl = require("@/assets/image/random_qiye/" +
            imgRandomNum +
            ".jpg");
        });
        // console.log("listlistlist", list);
        this.choose_list[0].child = list;
        this.Loading = false;
        // this.choose_list[1].child  =res.data.list
      });
    },
    // 获取企业列表其他
    getEnterpriselistType(id) {
      this.Loading = true;

      OrderService.getEnterpriseTypeList(id, 0, 20).then((res) => {
        this.choose_list[0].child = res.data.list;
        let list = res.data.list;
        list.map((i) => {
          if (i.qytx != undefined) {
            i.qytx = i.qytx && JSON.parse(i.qytx);
          } else {
            i.qytx = [];
          }
          let imgRandomNum = Number(i.F_Id.slice(-1));
          if (imgRandomNum == 0) {
            imgRandomNum++;
          } else if (imgRandomNum > 8) {
            imgRandomNum--;
          }

          i.imgRandomNumUrl = require("@/assets/image/random_qiye/" +
            imgRandomNum +
            ".jpg");
        });
        this.choose_list[0].child = list;
        this.Loading = false;
      });
    },
    getqiyetypes() {
      OrderService.getEnterpriseType("462229411828072837", 0, 20).then(
        (res) => {
          this.choose = res.data.list;
          this.choose.unshift({
            F_FullName: "全部",
            F_Id: "1",
          });
          let genduo = {
            F_FullName: "更多",
            F_Id: "0",
          };

          this.choose.push(genduo);
        }
      );
    },
    jumps(e) {
      this.result_list = [
        {
          fullName: "企业入驻",
        },
        {
          fullName: e.qymc,
        },
      ];
      sessionStorage.setItem("screening", JSON.stringify(this.result_list));
      var list = {
        id: e.F_Id,
        name: "企业入驻",
      };
      this.$router.push({ name: "enterprise_details", query: list });
    },
  },
};
</script>

<style lang="scss" scoped>
.enterprise {
  min-height: 300px;
  width: 80%;
  margin: 0 auto;
  background-color: #ffff;
  border-radius: 10px;
  padding: 20px 14px;
  position: relative;

  .choose {
    background-color: #ffff;

    ul {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .subselection {
        background-color: #fbf2ff;
        color: #5d207c;

        width: 102px;
        height: 43px;
        margin: 0 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .subselection_new {
        background-color: #5d207c;
        color: #ffffff;
        width: 102px;
        height: 43px;
        margin: 0 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .choose_center {
    min-height: 200px;
    margin-top: 35px;
    display: flex;
    justify-content: center;

    > div {
      position: relative;
      overflow: hidden;
      // width: 1500px;
      width: 98%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      .choose_li {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
        width: 1170px;
        .Settle_in {
          // margin: auto;
          float: left;
          width: 175px;
          // margin: 0 auto;
          // display: grid;
          text-align: center;
          // margin-bottom: 35px;
          margin: 0 10px 12px 10px;
        }

        .enterprise_img {
          width: 100%;
          height: 140px;
          border-radius: 10px;
          border: 1px solid #eeeeee;
          cursor: pointer;
        }

        .Settle_span {
          margin-top: 14px;
          font-size: 14px;
          color: #333333;
          font-weight: bold;
          letter-spacing: 1px;
          width: 95%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          letter-spacing: 1px;
          text-align: center;
        }
      }

      .choose_lis {
        // display: flex;
        justify-content: space-between;
        align-items: center;

        .Settle_in {
          float: left;
          margin: 0 auto;
          display: grid;
          text-align: center;
          margin-bottom: 35px;
          margin-right: 10px;
        }

        .enterprise_img {
          width: 180px;
          height: 140px;
          border-radius: 10px;
          margin-right: 10px;
        }

        .Settle_span {
          margin-top: 14px;
          font-size: 14px;
          color: #333333;
          font-weight: bold;
          letter-spacing: 1px;
          width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          letter-spacing: 1px;
          text-align: left;
        }
      }
    }
  }
}

.more {
  width: 10%;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 30px;

  right: 0;
}

::v-deep .el-loading-spinner .path {
  // stroke: #4f40ff;
  stroke: #5d207c;
}
</style>