import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Select,Input,InputNumber,Tabs,TabPane,Carousel,CarouselItem,Collapse,CollapseItem,Menu,MenuItem,MenuItemGroup,Submenu,Table,TableColumn,Pagination, 
  Breadcrumb,BreadcrumbItem,Icon,Form,FormItem,Option,Checkbox,Message,MessageBox,Loading,Dialog,Rate,Container,Header,Main,Footer,Aside,Row,Col,DatePicker,TimePicker,Empty,Tooltip
} from 'element-ui';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueQuillEditor from 'vue-quill-editor'


Vue.use(VueAxios, axios)


Vue.config.productionTip = false
Vue.use(Button).use(Select).use(Input).use(InputNumber).use(Tabs).use(TabPane).use(Carousel).use(CarouselItem).use(Collapse).use(CollapseItem)
.use(Menu).use(MenuItem).use(MenuItemGroup).use(Submenu).use(TableColumn).use(Table).use(Pagination).use(BreadcrumbItem).use(Breadcrumb).use(Icon)
.use(Form).use(FormItem).use(Option).use(Checkbox).use(Loading.directive).use(Dialog).use(Rate).use(Container).use(Header).use(Main).use(Footer).use(Aside).use(DatePicker).use(TimePicker).use(Tooltip)
.use(Row).use(Col).use(Empty)
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
// 富文本样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
// 响应式
import "@/assets/css/max2560.css"
import "@/assets/css/max1920.css"
import "@/assets/css/max1850.css"
import "@/assets/css/max1750.css"
import "@/assets/css/max1680.css"
import "@/assets/css/max1550.css"
import "@/assets/css/max1366.css"
import "@/assets/css/max1260.css"
import "@/assets/css/max1024.css"
import "@/assets/css/max992.css"
import "@/assets/css/max750.css"
import "@/assets/css/max479.css"
import "@/assets/css/mychoose.css"
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
