<template>
  <el-dialog
    title=""
    top="25vh"
    :visible.sync="isshows"
    width="30%"
    :before-close="handleClose"
  >
    <div class="dialogs">
      <!-- <div class="login-bg">
         <img src="../../../assets/image/resources2.png" alt=""/>
      </div> -->

      <div class="logins">
        <div class="login_caption">
          <img class="dengluicon1" src="@/assets/image/logo_new2.png" alt="" />
          <img
            class="dengluicon2"
            style="margin-left: 10px"
            src="@/assets/image/logo_new1.png"
            alt=""
          />
          <div class="designation_span">欢迎登录紫丁香资源服务共享平台</div>
        </div>
        <el-form :model="Account">
          <el-form-item label="" :label-width="formLabelWidth">
            <el-input
              prefix-icon="el-icon-user-solid"
              placeholder="请输入账户"
              v-model="Account.user"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="" :label-width="formLabelWidth">
            <el-input
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              show-password
              v-model="Account.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="" :label-width="formLabelWidth">
            <el-button
              type="primary"
              class="register"
              @click="dialogVisible"
              :loading="loging"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { OrderService } from "@/api/register";
import md5 from "js-md5";
export default {
  props: ["isshow"],
  data() {
    return {
      Account: {
        user: "",
        password: "",
      },
      formLabelWidth: "120px",
      loging: false,
      isshows: false,
      userinfor: {},
    };
  },
  methods: {
    // 用户登录
    dialogVisible() {
      this.loging = true;
      var origin = "password";
      let code = "";
      let jnpf_ticket = "";
      let client_id = "admin";
      let client_secret = "123456";
      let scope = "all";
      let timestamp = "";
      let grant_type = "password";
      sessionStorage.setItem("usermima", JSON.stringify(this.Account));
      let password = md5(this.Account.password);
      OrderService.getlogin(
        this.Account.user,
        // account,
        password,
        jnpf_ticket,
        origin,
        code,
        timestamp,
        grant_type
      ).then((res) => {
        if (res.code == 200) {
     
          sessionStorage.setItem("token", res.data.token);
   
          localStorage.setItem("jnpf_token", res.data.token);
          setTimeout(() => {
            this.getuserinfor();
          }, 500);
        } else {
          setTimeout(() => {
            this.loging = false;
          }, 1000);

          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    handleClose() {
      let vaule = {
        userinfor: { userName: "", headIcon: "" },
        ishows: false,
      };
      this.$emit("showCityName", vaule);
      this.Account = {
        user: "",
        password: "",
      };
    },
    // 获取用户信息
    getuserinfor() {
      let time = parseInt(new Date().getTime() / 1000) + "";
      OrderService.getCurrentUser(time).then((res) => {
        setTimeout(() => {
          this.loging = false;
        }, 500);
        if (res.code == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          this.userinfor = res.data.userInfo;
          sessionStorage.setItem(
            "userinfor",
            JSON.stringify(res.data.userInfo)
          );
          let lohinspan = "欢迎";
          // let lohinspan = "欢迎" + res.data.organizeInfo.fullName;
          sessionStorage.setItem("loginspan", lohinspan);
          let vaule = {
            userinfor: this.userinfor,
            lohinspan: lohinspan,
            ishows: false,
          };
          setTimeout(() => {
            this.$emit("showCityName", vaule);
            this.Account = {
              user: "",
              password: "",
            };
          }, 500);
        } else {
          this.loging = false;
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
  },
  watch: {
    isshow(val) {
      this.isshows = this.isshow;
    },
  },
};
</script>

<style lang="scss" scoped>
.General_carousel {
  position: absolute;
  width: 100%;

  top: 0;
  z-index: 1;

  img {
    border-top: 1px solid #66666600;
    background-size: 100%;
    width: 100%;
    height: 100%;
    // height: 373px;
  }
}

.Login_button {
  background: #5d207c;
  color: #fff;
  border: 1px solid #5d207c;
  padding: 8px 35px;
}

.dialogs {
  display: flex;
  border-radius: 10px;
  justify-content: center;

  .login-bg {
    width: 30%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logins {
    width: 70%;
    padding: 20px;
  }
}

.dengluicon1 {
  // width: 60px;
  height: 40px;
}

.dengluicon2 {
  // width: 60px;
  height: 40px;
}

.login_caption {
  margin-bottom: 20px;
}

.designation_span {
  font-size: 18px;
  color: #5d207c;
  font-weight: bold;
  // text-align: center;
}

.register {
  width: 100%;
  background: #5d207c;
  border-color: #5d207c;
}

.register:hover {
  background: #5d207cdd;
  border-color: #5d207cdd;
}

::v-deep .el-form-item__label {
  width: 80px !important;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-form-item__content {
  margin-left: 0px !important;
}

::v-deep .el-dialog__body {
  padding: 0px;
}

::v-deep .el-dialog__header {
  padding: 0px;
}

::v-deep .el-dialog__footer {
  padding: 5px 20px;
  text-align: center;
}

::v-deep .el-input__inner {
  border: 1px solid #5d207c;
}

::v-deep .el-input__inner:hover {
  border: 1px solid #5d207c;
  box-shadow: 2px 2px 2px 1px rgba(225, 177, 240, 0.234);
}

::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #5d207c;
}
</style>