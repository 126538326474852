<template>
  <div class="General">
    <div class="General_background">
      <!-- <div class="General_center"> -->
      <div class="left">
        <ul>
          <li v-for="(items, index) in classification" :key="index">
            <div class="icon">
              <img :src="items.icon" alt="" />
              <span @click="jumps(items, '')">{{ items.name }}</span>
            </div>
            <div class="icons">
              <span @click="jumps(items, items.suemun1)">{{
                items.suemun1.F_FullName
              }}</span>
              <div class="Research" v-if="items.suemun2 != undefined"></div>
              <span
                v-if="items.suemun2 != undefined"
                @click="jumps(items, items.suemun2)"
                >{{ items.suemun2.F_FullName }}</span
              >
              <div class="Research" v-if="items.suemun3 != undefined"></div>
              <span
                v-if="items.suemun3 != undefined"
                @click="jumps(items, items.suemun3)"
                >{{ items.suemun3.F_FullName }}</span
              >
            </div>
            <div class="line"></div>
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="logo">
          <img
            class="user"
            v-if="this.userinfor.headIcon != ''"
            :src="
              'http://114.117.195.108:3000' + `${this.userinfor.headIcon}` ||
              '@/assets/image/user.png'
            "
            alt=""
          />

          <div class="Login">
            <h4 v-if="this.userinfor.userName != ''">
              <span class="usernames">
                {{ "欢迎-" + this.userinfor.userName }}</span
              >
            </h4>
            <h4 v-if="this.userinfor.userName == ''">欢迎登录资源共享平台</h4>
            <div class="Personal">
              <el-button
                v-if="this.userinfor.userName == ''"
                class="Login_button"
                @click="Logins"
                >登录</el-button
              >
              <el-button
                v-if="this.userinfor.userName == ''"
                class="Login_button"
                @click="getregister"
                >注册</el-button
              >
              <div
                v-if="this.userinfor.userName != ''"
                @click="Individual('个人中心')"
                class="userinfor"
              >
                个人中心
              </div>
              <p v-if="this.userinfor.userName != ''" @click="Safeexit">
                安全退出
              </p>
            </div>
          </div>
        </div>
        <div class="Requirement">
          <ul>
            <li
              v-for="(itemsx, indexx) in Requirement"
              :key="indexx"
              @click="release(itemsx)"
            >
              <div class="Requirement_span">
                <img :src="itemsx.name" alt="" />
              </div>

              <img class="Requirement_iamg" :src="itemsx.url" alt="" />
            </li>
          </ul>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <div class="General_carousel">
      <el-carousel height="448px">
        <el-carousel-item v-for="(item, indexs) in bannerlist" :key="indexs">
          <a :href="item.link_url">
            <img
              :src="'http://114.117.195.108:3000' + item.image_url[0].url"
              alt=""
            />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 登录弹窗 -->
    <registerlogin
      :isshow.sync="isshow"
      @showCityName="updateCity"
    ></registerlogin>

    <CheckDialog :show.sync="show" :yuanqid="yuanqid"></CheckDialog>
  </div>
</template>

<script>
import { OrderService } from "../../../api/product";
import CheckDialog from "../../../components/CheckDialogs.vue";
import registerlogin from "../../../components/registerlogin.vue";
import md5 from "js-md5";
export default {
  props: ["Servicekej", "Service", "qiyechoose", "park"],
  components: { CheckDialog, registerlogin },
  data() {
    return {
      classification: [
        {
          icon: require("@/assets/image/classifications.png"),
          name: "公告",
          url: "announcement",
          suemun1: { F_FullName: "通知" },
          suemun2: { F_FullName: "需求" },
        },
        {
          icon: require("@/assets/image/classifications.png"),
          name: "服务资源",
          url: "Service_resource",
          suemun1: { F_FullName: "第三方服务机构" },
          suemun2: { F_FullName: "创业导师" },
        },
        {
          icon: require("@/assets/image/classifications.png"),
          name: "企业入驻",
          url: "Qiye_resource",
          suemun1: { F_FullName: "企业" },
          suemun2: { F_FullName: "团队" },
        },
        {
          icon: require("@/assets/image/classifications.png"),
          name: "园区资源",
          url: "Parkresources",
          suemun1: { F_FullName: "紫丁香孵化园区", id: "" },
        },
        {
          icon: require("@/assets/image/classifications.png"),
          name: "活动报名",
          url: "Activity",
          suemun1: { F_FullName: "科技" },
          suemun2: { F_FullName: "教育" },
          suemun3: { F_FullName: "公益" },
        },
      ],
      Requirement: [
        {
          names: "需求发布",
          url: require("@/assets/image/release.png"),
          name: require("@/assets/image/Requirement——span.png"),
        },
        {
          names: "专利检索",
          url: require("@/assets/image/Patent.png"),
          name: require("@/assets/image/Requirement——span1.png"),
        },
        {
          names: "双百双高",
          url: require("@/assets/image/Double.png"),
          name: require("@/assets/image/Requirement——span2.png"),
        },
        {
          names: "入驻申请",
          url: require("@/assets/image/Double12.png"),
          name: require("@/assets/image/Application.png"),
        },
      ],
      bannerlist: [],
      isshow: false,
      formLabelWidth: "120px",
      Account: {
        user: "",
        password: "",
      },
      userinfor: JSON.parse(sessionStorage.getItem("userinfor")) || {
        userName: "",
        headIcon: "",
      },
      loginspan: sessionStorage.getItem("loginspan"),
      loging: false,
      typeinfor: "",
      show: false,
      yuanqid: "392928103262898565",
      activeName: "392928103262898565",
      urlType: "",
      result_list: [
        {
          fullName: "服务资源",
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
      ],
    };
  },
  created() {
    this.getbanner();
  },
  mounted() {},
  methods: {
    Logins() {
      this.isshow = !this.isshow;
      // var url =
      //   "http://192.168.3.87:3000/#/login?redirect=%2F" +
      //   "account=admin&password=admin@123&origin=password&code=&timestamp=&client_id=admin&client_secret=123456&scope=all&grant_type=password&flag=true";
      // window.location.href = url;
      // var url =
      //   "http://hitzdx.com:8091/#/login?redirect=%2F"
      // // "http://192.168.3.87:3000/#/login?redirect=%2F" +
      // "account=admin&password=admin@123&origin=password&code=&timestamp=&client_id=admin&client_secret=123456&scope=all&grant_type=password&flag=true";
      // window.location.href = url;
    },
    // 个人中心
    Individual() {
      this.typeinfor = 3;
      this.Individuals();
    },

    // 退出
    Safeexit() {
      this.userinfor = { userName: "", headIcon: "" };
      sessionStorage.setItem("userinfor", JSON.stringify(this.userinfor));
      sessionStorage.setItem("token", "");

      this.loginspan = "欢迎登录资源共享平台";
      sessionStorage.setItem("loginspan", "欢迎登录资源共享平台");
      this.$message({
        message: "退出成功",
        type: "success",
      });
    },
    handleClose() {
      this.isshow = !this.isshow;
      this.Account = {
        user: "",
        password: "",
      };
    },
    // 用户登录
    dialogVisible() {
      this.loging = true;
      var origin = "password";
      let code = "";
      let timestamp = "";
      let client_id = "admin";
      let client_secret = "123456";
      let scope = "all";
      sessionStorage.setItem("usermima", JSON.stringify(this.Account));
      let password = md5(this.Account.password);
      OrderService.getlogin(
        this.Account.user,
        password,
        origin,
        code,
        timestamp,
        client_id,
        client_secret,
        scope
      ).then((res) => {
        if (res.code == 200) {
          sessionStorage.setItem("token", res.data.token);

          setTimeout(() => {
            // this.getuserinfor();
          }, 500);
        } else {
          setTimeout(() => {
            this.loging = false;
          }, 1000);

          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    Individuals() {
      var user = JSON.parse(sessionStorage.getItem("usermima"));
      // var url = "http://192.168.3.88:3000/#/home" +

      var url =
        "http://114.117.195.108:3000/" +
        this.urlType +
        "/#/login" +
        "&account=" +
        user.user +
        "&password=" +
        user.password +
        "&origin=password&code=&timestamp=&client_id=" +
        user.user +
        "&client_secret=123456&scope=all&grant_type=password&flag=true&type=" +
        this.typeinfor;
      window.location.href = url;
    },
    // 需求发布
    release(e) {
      if (this.userinfor.userName == "" && e.names !== "专利检索") {
        this.$message({
          message: "请登录",
          type: "warning",
        });

        return false;
      }
      var user = JSON.parse(sessionStorage.getItem("usermima"));
      switch (e.names) {
        case "需求发布":
          // var url = "http://10.1.203.45:3000/model/qyxqfb/#/login?redirect=%2F"+
          // "account=admin&password=admin@123&origin=password&code=&timestamp=&client_id=admin&client_secret=123456&scope=all&grant_type=password&flag=true&go=model/qyxqfb";
          this.urlType = "model/qyxqfb";

          // window.open = (url,"_blank");
          // window.open("http://114.117.195.108:3000/model/qyxqfb", "_blank")
          // if (this.userinfor.roleId == "396170683891114309") {
          //   this.$message({
          //     message: "暂无权限，请入驻企业",
          //     type: "warning",
          //   });
          // } else {
          this.typeinfor = 1;
          this.Individuals();
          // }

          break;
        case "双百双高":
          // if (this.userinfor.roleId == "396170683891114309") {
          //   this.$message({
          //     message: "暂无权限，请入驻企业",
          //     type: "warning",
          //   });
          // } else {
          this.urlType = "model/sbsq";
          // var url = "http://114.117.195.108:3000/#/login?redirect=%2F" +
          //   "account=admin&password=admin@123&origin=password&code=&timestamp=&client_id=admin&client_secret=123456&scope=all&grant_type=password&flag=true&go=model/qyxqfb";

          // window.open = (url, "_blank");
          // window.open("http://114.117.195.108:3000/model/sbsq", "_blank");
          this.typeinfor = 2;
          this.Individuals();
          // }

          break;
        case "专利检索":
          window.open("https://www.patent.com.cn/", "_blank");
          break;
        case "入驻申请":
          // // window.open("http://114.117.195.108:3000/model/qyrzsq", "_blank");
          // var url = "http://114.117.195.108:3000/#/login?redirect=%2F" +
          //   "account=admin&password=admin@123&origin=password&code=&timestamp=&client_id=admin&client_secret=123456&scope=all&grant_type=password&flag=true&go=model/qyxqfb";

          // window.open = (url, "_blank");
          this.urlType = "model/qyrzsq";
          this.typeinfor = 4;
          this.Individuals();
          // this.show = true;
          break;
        default:
          break;
      }
    },
    // 获取用户信息
    getuserinfor() {
      let time = parseInt(new Date().getTime() / 1000) + "";
      OrderService.getCurrentUser(time).then((res) => {
        setTimeout(() => {
          this.loging = false;
        }, 500);
        if (res.code == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          this.userinfor = res.data.userInfo;

          this.loginspan = res.data.organizeInfo.fullName;
          sessionStorage.setItem(
            "userinfor",
            JSON.stringify(res.data.userInfo)
          );
          setTimeout(() => {
            this.isshow = false;
          }, 500);
        } else {
          this.loging = false;
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    getregister() {
      sessionStorage.setItem("page_id", 0);
      this.$router.push({ name: "register" });
    },
    updateCity(data) {
      this.isshow = data.ishows;
      this.userinfor = data.userinfor;
      this.loginspan = data.lohinspan;
    },
    jumps(item, son) {
      console.log("item son", son, item);
      if (item.name == "公告") {
        this.$router.push({
          name: "announcement",
          query: son.F_FullName == "需求" ? 2 : 1,
        });
      } else if (item.name == "活动报名") {
        let type = "全部";
        if (son) {
          type = son.F_FullName;
        }

        this.$router.push({ name: "Activity", query: { type: type } });
      } else if (item.name == "园区资源") {
        var lists = {
          name: item.name,
          id: son.F_Id,
        };

        this.result_list[0].fullName = item.name;
        this.result_list[1].fullName = son.F_FullName;
        this.$router.push({
          name: "Parkresources",
          query: lists,
        });
      } else if (item.name == "服务资源") {
        if (son == "更多") {
          this.$router.push({
            name: "Service_resource",
            query: { type: "全部" },
          });
        } else {
          var lists = {
            type: son.F_FullName,
            id: son.F_Id,
          };

          this.result_list[0].fullName = item.name;
          this.result_list[1].fullName = son.F_FullName;

          this.$router.push({
            name: "Service_resource",
            query: lists,
          });
        }
      } else if (item.name == "企业入驻") {
        if (son == "更多") {
          this.$router.push({
            name: "Qiye_resource",
            query: { type: "全部" },
          });
        } else {
          var lists = {
            type: son.F_FullName,
            id: son.F_Id,
          };

          this.result_list[0].fullName = item.name;
          this.result_list[1].fullName = son.F_FullName;

          this.$router.push({
            name: "Qiye_resource",
            query: lists,
          });
        }
      }
      sessionStorage.setItem("screening", JSON.stringify(this.result_list));
      //   sessionStorage.setItem("screening", JSON.stringify(this.result_list));
    },
    // 获取轮播图
    getbanner() {
      let kkeyy = "";
      let offs = 0;
      let pages = 10;
      OrderService.getbannerlist(kkeyy, offs, pages).then((res) => {
        if (res.code == 200) {
          let list = res.data.list;

          list.map((item) => {
            item.image_url = JSON.parse(item.image_url);
          });
          this.bannerlist = list;
        }
      });
    },
  },
  watch: {
    Servicekej(val) {
      this.classification[2].suemun1 = this.Servicekej[1];
      this.classification[2].suemun2 = this.Servicekej[2];
    },
    Service(val) {
      this.classification[1].suemun1 = this.Service[1];
      this.classification[1].suemun2 = this.Service[2];
    },
    qiyechoose(val) {
      this.classification[2].suemun1 = this.qiyechoose[0];
      this.classification[2].suemun2 = this.qiyechoose[1];
    },
    park(val) {
      this.classification[3].suemun1.fullName = val[0].title;
      this.classification[3].suemun1.id = val[0].id;
    },
  },
};
</script>

<style lang="scss" scoped>
.General {
  position: relative;
}

.General_background {
  z-index: 99;

  width: 100%;
  //   background-image: url("@/assets/image/big_img.png");
  // border-top: 1px solid #666666;
  display: flex;
  justify-content: center;
  height: 448px;

  // .General_center {
  //   z-index: 99;
  //   position: absolute;
  //   width: 80%;
  //   height: 100%;
  //   // margin: 0 auto;
  //   display: flex;
  //   justify-content: space-between;

  // align-items: center;
  .left {
    width: 20%;
    position: relative;
    height: 100%;
    position: absolute;
    z-index: 99;
    left: 10%;

    ul {
      position: absolute;
      width: 184px;
      background-color: #f6f8fe;
      padding: 0 10px 0 10px;
      height: 100%;

      li {
        margin-top: 10px;

        .icon {
          display: flex;
          justify-content: left;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
          }

          span {
            font-size: 14px;
            font-weight: bold;
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .icons {
          margin: 10px 0;
          padding: 5px;
          font-size: 11px;
          color: #666666;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          span {
            text-align: center;
            // width: 60px;
          }

          .Research {
            background: #666666;
            width: 0.5px;
            height: 10px;
            margin: 0 5px;
          }
        }

        .line {
          margin: 7px 0;
          width: 100%;
          height: 1px;
          background: #e7e9ef;
        }
      }
    }
  }

  .left ul li:nth-child(3) .icons {
    display: flex;
    // justify-content: left !important;

    .Research {
      margin: 0 10px;
    }
  }

  .right {
    width: 250px;
    padding: 0 11px;
    background-color: #f6f8fe;
    position: absolute;
    z-index: 99;
    right: 10%;

    .logo {
      margin: 20px 0;
      padding-top: 5px;
      display: flex;
      align-items: flex-end;

      .user {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .Login {
        margin-left: 10px;
        width: 100%;

        h4 {
          margin: 0;
          padding: 0;
          font-size: 14px;
          letter-spacing: 2px;
          width: 180px;
          color: #5d207c;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
        }

        .Personal {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 12px;

          span,
          p {
            margin: 0;
            padding: 0;
            font-weight: bold;
            letter-spacing: 1px;
            cursor: pointer;
          }

          p {
            color: #00408b;

            text-decoration: underline;
          }
        }
      }
    }

    .Requirement {
      margin-top: 20px;

      ul {
        li {
          position: relative;
          background-color: #20407e;
          padding: 10px;
          margin: 32px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .Requirement_iamg {
            position: absolute;
            width: 80px;
            height: 80px;
            right: 10px;
            top: -30px;
          }

          .Requirement_span {
            font-size: 24px;
            font-family: zihun5hao;
            font-weight: normal;
            color: #ffffff;
            margin-right: 55px;
          }
        }
      }
    }

    .Requirement ul li:nth-child(2) {
      background-color: #5d207c;
    }

    .Requirement ul li:nth-child(4) {
      background-color: #206e7e;
    }
  }

  .left ul li:nth-child(7) .line {
    display: none;
  }

  // }
}

.userinfor {
  margin: 0;
  padding: 0;
  display: flex;
  font-weight: bold;
  cursor: pointer;

  // width: 50%;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   text-overflow: ellipsis;
  .usernames {
    margin: 0;
    padding: 0;
    max-width: 52px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.General_carousel {
  position: absolute;
  width: 100%;

  top: 0;
  z-index: 1;

  img {
    border-top: 1px solid #66666600;
    background-size: 100%;
    width: 100%;
    height: 100%;
    // height: 373px;
  }
}

.Login_button {
  background: #5d207c;
  color: #fff;
  border: 1px solid #5d207c;
  padding: 8px 35px;
}
</style>