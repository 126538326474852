<template>
  <div class="bottom">
    <div class="bottom_center">
      <div class="icon">
        <img class="logo1" src="../assets/image/logo_new2.png" alt="" />
        <img class="Schoolbadge" src="../assets/image/logo_new1.png" alt="" />
      </div>
      <span>Copyright @ 2023 - 2024 哈尔滨工业大学重庆研究院版权所有</span>
      <div class="ICP">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          ><span style="white-space: nowrap">渝ICP备2023007754号-1</span></a
        >
        <p @click="jump">技术支持：维尔斯科技</p>
      </div>
      <p>
        地址:
        重庆市渝北区协同大道辅路联合产业孵化基地4号楼哈尔滨工业大学重庆研究院
        邮编: 400010
      </p>
      <p>访问人数: 14927273</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    jump() {
      window.open("http://www.width168.com/", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom {
  //   position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .bottom_center {
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      img {
        height: 43px;
        margin-bottom: 10px;
      }
      .Schoolbadge {
        margin-left: 20px;
      }
    }
    span {
      font-size: 14px;
      color: #642a82;
      margin-bottom: 10px;
    }
    p {
      padding: 0;
      margin: 10px;
      font-size: 14px;
      color: #642a82;
    }
    .ICP {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 10px;
      font-size: 14px;
      color: #642a82;
      p span {
        cursor: pointer;
      }
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
</style>