import service from "../utils/request";
export const OrderService = {
  //模糊查询
  getfuzzy_search(id) {
    return service.get("api/visualdev/OnlineDev/" + id + "/Listbycd", {});
  },
  // 登录
  getregister(
    id,
    sortCode,
    enabledMark,
    account,
    realName,
    organizeId,
    organizeIdTree,
    managerId,
    positionId,
    roleId,
    password,
    groupId,
    description,
    headIcon,
    gender,
    nation,
    nativePlace,
    entryDate,
    certificatesType,
    certificatesNumber,
    education,
    birthday,
    telePhone,
    landline,
    mobilePhone,
    email,
    urgentContacts,
    urgentTelePhone,
    postalAddress
  ) {
    return service.post("/api/permission/Users", {
      id,
      sortCode,
      enabledMark,
      account,
      realName,

      organizeId,
      organizeIdTree,
      managerId,
      positionId,
      roleId,
      groupId,
      password,
      description,
      headIcon,
      gender,
      nation,
      nativePlace,
      entryDate,
      certificatesType,
      certificatesNumber,
      education,
      birthday,
      telePhone,
      landline,
      mobilePhone,
      email,
      urgentContacts,
      urgentTelePhone,
      postalAddress,
    });
  },
  submitAccount(account, dwxz, qymc, yyzzdm, password, checkpassword, tel, email, mpassword) {
    return service.post("/api/extDev/qyRegister", {
      account,
      dwxz,
      qymc,
      yyzzdm,
      password,
      checkpassword,
      tel,
      email,
      mpassword,
    });
  },
  // 提交企业信息
  getregister_information(id, parentId, fullName, enCode, enabledMark, sortCode, description, propertyJson) {
    return service.post("/api/permission/Organize/CreateNew", {
      id,
      parentId,
      fullName,
      enCode,
      enabledMark,
      sortCode,
      description,
      propertyJson,
    });
  },
  // 协议

  getagreement(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463384858006126981/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },

  // 获取服务资源列表 kkey有值为详情
  getServiceList(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463674038095447621/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  // 获取服务资源列表 --分类
  getServiceTypeList(zylx, offs, pages) {
    return service.post("/api/system/DataInterface/463982366570841669/Actions/Response", {
      zylx,
      offs,
      pages,
    });
  },
  //获取平台实时数据
  getRealData(data) {
    return service.get("/api/system/DataInterface/519817925134123845/Actions/Response", {});
  },
  //获取筛选类型
  getdictionary(data) {
    return service.get("/api/system/DictionaryData/380191028998600453/byid", {});
  },
  //筛选其他
  getdictionary_other(id) {
    return service.get("/api/system/DictionaryData/" + id + "/byid", {});
  },
  //筛选地区
  getregion(id) {
    return service.get("/api/system/Area/" + id + "/bycd", {});
  },

  //服务资源提交合作
  getqiyeCheck(id, data) {
    return service.post("/api/visualdev/OnlineDev/384202258042099269", {
      id,
      data,
    });
  },
  //服务资源申请接口
  getsestimate_(data) {
    return service.post("/api/system/DataInterface/471066140982902981/Actions/Response", data);
  },
  // 服务资源判断申请
  getsestimate(currentPage, pageSize, sort, sidx, menuId, queryJson) {
    return service.post("/api/visualdev/OnlineDev/384202258042099269/Listbycd", {
      currentPage,
      pageSize,
      sort,
      sidx,
      menuId,
      queryJson,
    });
  },

  //企业列表 getfuzzyqiyexq_details
  getEnterpriseList(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463685004468160069/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  //修改接口
  // getEnterpriseList(kkeyy, offs, pages) {
  //   return service.post("/api/system/DataInterface/522715112671281989/Actions/Response", {
  //     kkeyy,
  //     offs,
  //     pages,
  //   });
  // },
  //企业分类列表
  getEnterpriseTypeList(dwxz, offs, pages) {
    return service.post("/api/system/DataInterface/463972205038929477/Actions/Response", { dwxz, offs, pages });
  },
  //企业发布资源列表详情
  getqiyelist_details(id) {
    return service.get("/api/visualdev/OnlineDev/380076238879880837/" + id + "/GetFuwuList", {});
  },
  //企业类型--数据字典
  getEnterpriseType(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463694585827165765/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },

  //通知公告
  getannouncement(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463717296985081413/Actions/Response", { kkeyy, offs, pages });
  },
  //通知公告详情  列表
  getannouncementList(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463717296985081413/Actions/Response", { kkeyy, offs, pages });
  },
  //通知类型查询列表
  getannouncementtypeList(tzlx, offs, pages) {
    return service.post("/api/system/DataInterface/463445997071106437/Actions/Response", {
      tzlx,
      offs,
      pages,
    });
  },
  //通知类型--数据字典
  getannouncementtype(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463694585827165765/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },

  // 需求 详情  列表
  getdemandlist(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463683256911398469/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  // 需求分类列表
  getdemandtypeList(xqlx, offs, pages) {
    return service.post("/api/system/DataInterface/463733971012814405/Actions/Response", {
      xqlx,
      offs,
      pages,
    });
  },
  //需求类型--数据字典
  getdemandtype(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463694585827165765/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  //需求公告详情
  getannouncement_demand(id) {
    return service.get("/api/visualdev/OnlineDev/380084615576057477/" + id + "/DataChangeinfo", {});
  },
  // 463256363405934981 = 研发资源类型
  // 462690494149820805 = 活动分类
  // 461901667500032261 = 资源类别
  // 461902633599238405 = 服务类别
  // 462682277416010117 = 通知类型
  // 462229411828072837 = 单位性质
  // 462721523686310277 = 价格分类
  // 462716240482271621 = 周期分类
  // 462721755249639813 = 人才类别
  // 462721876121092485 = 职称
  // 462722053280104837 = 技术专业领域
  // 462716003780919685 = 需求类别
  // 获取数据字典类型
  getType(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463694585827165765/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  // 活动类型菜单
  getactivityType(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463694585827165765/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  // 获取活动列表 详情
  getActivityList(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463688988566226501/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  // 获取活动分类 查询列表
  getActivityTypeList(hdfl, offs, pages) {
    return service.post("/api/system/DataInterface/463800461607044677/Actions/Response", {
      hdfl,
      offs,
      pages,
    });
  },
  // 参加活动
  Participate(hd_id, bmdw, bmr, hd_bmrtelid) {
    return service.post("/api/system/DataInterface/463671016715978309/Actions/Response", {
      hd_id,
      bmdw,
      bmr,
      hd_bmrtelid,
    });
  },
  // 活动申请判断申请
  getactivitysestimate(currentPage, pageSize, sort, sidx, menuId, queryJson) {
    return service.post("/api/visualdev/OnlineDev/392193811494782021/Listbycd", {
      currentPage,
      pageSize,
      sort,
      sidx,
      menuId,
      queryJson,
    });
  },

  //园区详情
  getfupark(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463687185019700805/Actions/Response", { kkeyy, offs, pages });
  },
  // 园区获取
  getmuns(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463687185019700805/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  // 园区--我要入驻-表单
  addPark(yqid, yuyueshijian, yuyueren, yuyuerentel) {
    return service.post("/api/system/DataInterface/463995062439642693/Actions/Response", {
      yqid,
      yuyueshijian,
      yuyueren,
      yuyuerentel,
    });
  },

  //园区提交合作
  getCheck(data, flowId, status) {
    return service.post("/api/visualdev/OnlineDev/383492344978116613/yqsq", {
      data,
      flowId,
      status,
    });
  },
  // 园区资源判断申请
  getyuanqusestimate(currentPage, pageSize, sort, sidx, menuId, queryJson) {
    return service.post("/api/visualdev/OnlineDev/383492344978116613/Listbycd", {
      currentPage,
      pageSize,
      sort,
      sidx,
      menuId,
      queryJson,
    });
  },

  // 技术服务资源
  getScientific_research(currentPage, pageSize, sort, sidx, menuId, queryJson) {
    return service.post("/api/visualdev/OnlineDev/393631982480838725/Listbycd", {
      currentPage,
      pageSize,
      sort,
      sidx,
      menuId,
      queryJson,
    });
  },
  // 技术服务资源详情
  getScientific_details(id) {
    return service.get("/api/visualdev/OnlineDev/393631982480838725/" + id, {});
  },
  // 技术服务资源
  scientific_apply(id, data) {
    return service.post("/api/visualdev/OnlineDev/398633192015328901", {
      id,
      data,
    });
  },
  //技术服务资源分类
  getscienceclassification() {
    return service.get("/api/system/DictionaryData/393609194994978885/Data/Selector", {});
  },

  //首页banner轮播图
  getbannerlist(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463666716283504197/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  //首页banner服务资源轮播图
  getbannerservicelist(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463671311357445701/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },
  //首页banner科技资源轮播图
  getbannersciencelist(kkeyy, offs, pages) {
    return service.post("/api/system/DataInterface/463671311357445701/Actions/Response", {
      kkeyy,
      offs,
      pages,
    });
  },

  // 企业资源搜索
  getenterprisesearch(currentPage, pageSize, sort, sidx, menuId, queryJson) {
    return service.post("/api/visualdev/OnlineDev/383891787082667013/Listbycd", {
      currentPage,
      pageSize,
      sort,
      sidx,
      menuId,
      queryJson,
    });
  },
};
