<template>
  <div class="search">
    <el-dialog
      :visible.sync="dialogVisibles"
      width="50%"
      top="10vh"
      :before-close="handleClose"
    >
      <el-input
        v-model="vlaue"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        class="seek"
      ></el-input>
      <div class="Search_list">
        <span>资源服务</span>
        <div class="lists" v-for="(item,index) in lits" :key="index">
        <div>{{item.name}}</div>
        <div>{{item.vlaues}}</div>
        </div>
      </div>
      <div class="bottom"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialogVisible", "Search_content"],
  data() {
    return {
      dialogVisibles: this.dialogVisible,
      vlaue: this.Search_content,
      center: "",
      lits: [
        {
          name: "测试标题",
          vlaues: "测试测试测网速西城往事",
        },
        {
          name: "测试标题",
          vlaues: "测试测试测网速西城往事",
        },
        {
          name: "测试标题",
          vlaues: "测试测试测网速西城往事",
        },
        {
          name: "测试标题",
          vlaues: "测试测试测网速西城往事",
        }, {
          name: "测试标题",
          vlaues: "测试测试测网速西城往事",
        }, {
          name: "测试标题",
          vlaues: "测试测试测网速西城往事",
        }, {
          name: "测试标题",
          vlaues: "测试测试测网速西城往事",
        },
      ],
    };
  },
  methods: {
    handleClose(done) {
      this.$emit("update:dialogVisible", false);
      this.dialogVisibles = false;
    },
  },
  watch: {
    dialogVisible(val) {
      this.dialogVisibles = this.dialogVisible;
    },
    Search_content(val) {
      this.vlaue = val;

    },
  },
};
</script>

<style lang="scss" scoped>
.Search_list {
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  .lists{
    margin: 10px;
    padding: 10px;
  border:2px solid #5d207c ;
  }
}
::v-deep .el-dialog {
  border-radius: 10px;
  min-height: 200px;
  max-height: 450px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 40px 20px;
}
.seek {
  border-radius: 10px;
}
::v-deep .seek .el-input__inner {
  height: 50px !important;
  border-radius: 10px !important;
  border-color: #5d207c;
}
::v-deep .seek .el-input__inner:hover {
  border-color: #5d207c;
}
// 调整滚动条的样式
::-webkit-scrollbar 
{ 
  width: 5px; 
}
::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(0,0,0,0.1);
}
</style>