<template>
  <div class="home">
    <General_background
      :Servicekej="Servicekej"
      :Service="Service"
      :qiyechoose="qiyechoose"
      :park="park"
    ></General_background>
    <div class="headline">
      <img src="@/assets/image/icon_left.png" alt="" />
      <div class="Notice">
        <span class="Notice_span">通知公告</span>
        <p class="Notice_p">NOTICE</p>
      </div>
      <img src="@/assets/image/iocn_right.png" alt="" />
    </div>
    <Notice :announcementlist="announcementlist"></Notice>
    <div class="headline">
      <img src="@/assets/image/icon_left.png" alt="" />
      <div class="Notice">
        <span class="Notice_span">入驻企业</span>
        <p class="Notice_p">ENTERPRISE</p>
      </div>
      <img src="@/assets/image/iocn_right.png" alt="" />
    </div>
    <Enterprise :Enterpriselist="Enterpriselist"></Enterprise>
    <div class="headline">
      <img src="@/assets/image/icon_left.png" alt="" />
      <div class="Notice">
        <span class="Notice_span">服务资源</span>
        <p class="Notice_p">RESOURCES</p>
      </div>
      <img src="@/assets/image/iocn_right.png" alt="" />
    </div>
    <Resource :Service="Service"></Resource>

    <div class="headline">
      <img src="@/assets/image/icon_left.png" alt="" />
      <div class="Notice">
        <span class="Notice_span">园区展示</span>
        <p class="Notice_p">RESOURCES</p>
      </div>
      <img src="@/assets/image/iocn_right.png" alt="" />
    </div>
    <Park :park="park"></Park>
  </div>
</template>

<script>
import General_background from "./components/General_background.vue";
import Notice from "./components/Notice.vue";
import Enterprise from "./components/enterprise.vue";
import Resource from "./components/resource.vue";
import Park from "./components/Park.vue";
import Scientific_research from "./components/Scientific_module.vue";
import { OrderService } from "../../api/product";

export default {
  name: "HomeView",
  components: {
    General_background,
    Notice,
    Enterprise,
    Resource,
    Park,
    Scientific_research,
  },
  data() {
    return {
      input: "",
      park: {},
      Service: [],
      Servicekej: [],
      Servicelist: [],
      Enterpriselist: [],
      announcementlist: [],
      qiyechoose: [],
      bannerlist: [],
      bannerlistfuwu: null,
      bannerlistkeji: null,
    };
  },
  mounted() {
    this.getmunsList();
    this.getservice();
    this.getservicekeji();
    this.getqiyetypes();
 
    // this.getEnterpriselist()
    // this.getannouncementlist()
  },
  methods: {
    // 获取菜单
    getmunsList() {
      // kkeyy, offs, pages
      OrderService.getmuns("", 0, 20).then((res) => {
        this.park = res.data.list;
        console.log("homeopark", this.park);
        // this.park.unshift({
        //   title:"全部",
        //   id:'145031258452c'
        // })
      });
    },
    // 服务资源-全部类型
    getservice() {
      OrderService.getType("461901667500032261", 0, 20).then((res) => {
        this.Service = res.data.list;
        // this.Service.unshift({
        //   F_FullName: "全部",
        //   F_Id: "",
        // });
        let genduo = {
          F_FullName: "更多",
          F_Id: "0",
        };
        console.log('服务资源-全部类型',res.data.list)
        this.Service.push(genduo);
      });
    },


  

    // 技术服务资源分类
    getservicekeji() {
      OrderService.getscienceclassification().then((res) => {
        this.Servicekej = res.data.list;
        this.Servicekej.unshift({
          fullName: "全部",
          id: "14503125845keji",
        });
        let genduo = {
          fullName: "更多",
          id: "0",
        };
        this.Servicekej.push(genduo);
      });
    },
    // 获取企业列表--全部列表
    getEnterpriselist() {
      OrderService.getEnterpriseTypeList("", 0, 20).then((res) => {
        this.Enterpriselist = res.data.list;
      });
    },
    // 获取企业菜单
    getqiyetypes() {
      OrderService.getEnterpriseType("462229411828072837", 0, 20).then(
        (res) => {
          this.qiyechoose = res.data.list;
        }
      );
    },
    // 获取通知列表
    getannouncementlist() {
      let pages = 20;
      let offs = 0;
      let kkeyy = "";
      OrderService.getannouncement(kkeyy, offs, pages).then((res) => {
        this.announcementlist = res.data.list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.headline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
  .Notice {
    margin: 0 36px;
    text-align: center;
    .Notice_span {
      color: #000;
      margin: 0;
      padding: 0;
      font-size: 26px;
      font-weight: bold;
    }
    .Notice_p {
      margin: 0;
      padding: 0;
      color: #666666;
      font-size: 12px;
    }
  }
}
</style>
