<template>
  <div class="Notice">
    <div class="lefts">
      <div class="headline_lines">
        <div class="lines">
          <div class="line"></div>
          <span>通知公告</span>
        </div>
        <div class="more" @click="getmore">
          <!-- <img src="@/assets/image/more.png" alt="" /> -->
          <span class="morespan">更多</span>
        </div>
      </div>
      <div class="muns">
        <ul class="muns_list">
          <li v-for="(items, index) in Notice" :key="index" :class="activeName == items.F_Id ? 'menus_new' : 'menus'"
            @click="handleClick(items)">
            <span>{{ items.F_FullName }}</span>
          </li>
        </ul>
      </div>
      <div class="muns_center" v-loading="Loading">
        <ul>
          <li v-for="(items, indexs) in muns_center" :key="indexs" @click="skip(items)">
            <el-tooltip class="item" effect="dark" :content="items.title" placement="top-start">
              <span class="Notice_title">{{ items.title }}</span>
            </el-tooltip>
            <p class="Notice_time">{{ items.tzsj }}</p>
          </li>
        </ul>
        <el-empty v-if="muns_center.length == 0"></el-empty>
      </div>
    </div>
    <div class="lefts">
      <div class="headline_lines">

        <div class="lines">
          <div class="line"></div>
          <span>需求公告</span>
        </div>
        <div class="more" @click="getmore_demand">
          <!-- <img src="@/assets/image/more.png" alt="" /> -->
          <span class="morespan">更多</span>
        </div>
      </div>
      <div class="muns">
        <ul class="muns_list">
          <li v-for="(items, index) in Notices" :key="index" :class="activeNames == items.F_Id ? 'menus_new' : 'menus'"
            @click="handleClicks(items)">
            <span>{{ items.F_FullName }}</span>
          </li>
        </ul>
      </div>
      <div class="muns_center" v-loading="Loadingnew">
        <ul>
          <li v-for="(items, indexs) in Noticesnew" :key="indexs" @click="skips(items)">
            <el-tooltip class="item" effect="dark" :content="items.qymc" placement="top-start">
              <span class="Notice_title">{{ items.qymc }}</span>
            </el-tooltip>
            <p class="Notice_time">{{ items.fbsj }}</p>
          </li>
        </ul>
        <el-empty v-if="Noticesnew.length == 0"></el-empty>
        <!-- <div class="nodata" v-if="(Noticesnew.length == 0 )">暂无数据</div> -->
      </div>
    </div>
    <div class="lefts">
      <div class="headline_lines">
        <div class="lines">
          <div class="line"></div>
          <span>活动报名</span>
        </div>
        <div class="more" @click="getmore_activity">
          <!-- <img src="@/assets/image/more.png" alt="" /> -->
          <span class="morespan">更多</span>
        </div>
      </div>
      <div class="muns">
        <!-- <ul class="muns_list">
          <li v-for="(items, index) in Noticesactivity" :key="index"
            :class="activeName1 == items.F_Id ? 'menus_new' : 'menus'" @click="activitys(items)">
            <span>{{ items.F_FullName }}</span>
          </li>
        </ul> -->
      </div>
      <div class="muns_center" v-loading="Loadings">
        <ul>
          <li v-for="(items, indexs) in activitylist" :key="indexs" @click="skiphdong(items)">
            <el-tooltip class="item" effect="dark" :content="items.hdmc" placement="top-start">
              <span class="Notice_title">{{ items.hdmc }}</span>
            </el-tooltip>
            <p class="Notice_time">{{ items.hdrq }}</p>
          </li>
        </ul>
        <el-empty v-if="activitylist.length == 0"></el-empty>
        <!-- <div class="nodata" v-if="(Noticesnew.length == 0 )">暂无数据</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { OrderService } from "../../../api/product";
export default {
  props: ["announcementlist"],
  data() {
    return {
      activeName: "1",
      activeNames: "2",
      activeName1: "1",
      Notice: [
        {
          label: "全部",
          name: "1",
        },
        {
          label: "高新企业",
          name: "2",
        },
        {
          label: "高新企业",
          name: "3",
        },
        {
          label: "高新企业",
          name: "4",
        },
      ],
      Notices: [],

      Noticesactivity: [],
      muns_center: [
        {
          vuales:
            "【通知】通知、公告文本占位符，通知、公告文本占位符，通知、通知、文本/通知、通知、文本通知、通知、文本",
          time: "2022-12-10",
        },
        
      ],
      Noticesnew: [],
      activitylist: [],
      Loading: false,
      Loadingnew: false,
      Loadings: false,
    };
  },
  mounted() {

    this.getannouncementtype();
    this.getdemandtype();
    this.getactivityType();

    this.getannouncementlistAll();
    this.getdemandlistAll();
    this.getactivitylistAll();


    // this.muns_center =this.announcementlist
  },
  methods: {
    handleClick(items) {
   
      this.activeName = items.F_Id;
      if (items.F_FullName == '全部') {
        this.getannouncementlistAll();
      } else {
        this.getannouncementlistType(items.F_Id);
      }
    },
    handleClicks(items) {
      this.activeNames = items.F_Id;
      if (items.F_Id == "2") {
        this.getdemandlistAll();
      } else {
        this.getdemandlistType(items.F_Id);
      }
    },
    activitys(items) {
      this.activeName1 = items.F_Id;
      if (items.F_Id == "1") {
        this.getactivitylistAll();
      } else {
        this.getactivitylistType(items.F_Id);
      }
    },
    // 跳通知
    getmore() {
      this.$router.push({ name: "announcement", query: 1 });
    },
    // 跳需求
    getmore_demand() {
      this.$router.push({ name: "announcement", query: 2 });
    },
    getmore_activity() {
      this.$router.push({ name: "Activity" });
    },
    // 获取通知类型
    getannouncementtype() {
      let kkeyy = "462682277416010117";
      let offs = 0;
      let pages = 20;
      OrderService.getannouncementtype(kkeyy, offs, pages).then((res) => {
      
        this.Notice = res.data.list;
        this.Notice.unshift({
          F_FullName: "全部",
          F_Id: "1",
        });
      });
    },
    // 获取需求类型
    getdemandtype() {
      let kkeyy = "462716003780919685";
      let offs = 0;
      let pages = 20;
      OrderService.getdemandtype(kkeyy, offs, pages).then((res) => {
        // this.Notices.push(res.data.list[0]);
        // this.Notices.push(res.data.list[1]);
        // this.Notices.push(res.data.list[2]);
        this.Notices = res.data.list;
        this.Notices.unshift({
          F_FullName: "全部",
          F_Id: "2",
        });
      });
    },
    // 获取活动类型
    getactivityType() {
      let kkeyy = "462690494149820805"; //活动分类
      let offs = 0;
      let pages = 20;
      OrderService.getactivityType(kkeyy, offs, pages).then((res) => {
        this.Noticesactivity = res.data.list;
        this.Noticesactivity.unshift({
          F_FullName: "全部",
          F_Id: "1",
        });
      });
    },
    // 获取通知列表--全部
    getannouncementlistAll() {
      this.Loading = true;
      let pages = 10;
      let offs = 0;
      let kkeyy = ''
      OrderService.getannouncement(kkeyy, offs, pages).then((res) => {
        this.muns_center = res.data.list;
        this.Loading = false;
      });
    },
    getannouncementlistType(e) {
      this.Loading = true;

      let pages = 10;
      let offs = 0;
      let tzlx = e;
      OrderService.getannouncementtypeList(tzlx, offs, pages).then((res) => {
        this.muns_center = res.data.list;
        this.Loading = false;
      });
    },
    getdemandlistAll() {
      this.Loadingnew = true;
      let pages = 10;
      let offs = 0;
      let kkeyy = ''

      OrderService.getdemandlist(kkeyy, offs, pages).then((res) => {
        this.Noticesnew = res.data.list;
        this.Loadingnew = false;
      });
    },
    getdemandlistType(e) {
      this.Loadingnew = true;
      let pages = 10;
      let offs = 0;
      let tzlx = e;
      OrderService.getdemandtypeList(tzlx, offs, pages).then((res) => {
        this.Noticesnew = res.data.list;
        this.Loadingnew = false;
      });
    },
    // 获取活动列表
    getactivitylistAll() {
      this.Loadings = true;
      let pages = 10;
      let offs = 0;
      let kkeyy = '';

      OrderService.getActivityList(
        kkeyy, offs, pages
      ).then((res) => {
        this.activitylist = res.data.list;
        this.Loadings = false;
      });
    },
    // 获取其他活动列表
    getactivitylistType(e) {
      this.Loadings = true;
      let pages = 10;
      let offs = 0;
      let hdfl = e;
      OrderService.getActivityTypeList(
        hdfl, offs, pages
      ).then((res) => {
        this.activitylist = res.data.list;
        this.Loadings = false;
      });
    },

    skip(e) {
      var menu = [
        {
          F_FullName: "通知公告",
        },
        {
          F_FullName: e.title,
        },
        {
          F_FullName: "",
        },
        {
          F_FullName: "",
        },
        {
          F_FullName: "",
        },
      ];
      sessionStorage.setItem("record", JSON.stringify(menu));
      let list = {
        id: e.F_Id,
        name: "通知",
      };
      sessionStorage.setItem("page_id", 2);
      this.$router.push({ name: "Noticeparticulars", query: list });
    },
    skips(e) {
      var menu = [
        {
          F_FullName: "通知公告",
        },
        {
          F_FullName: e.qymc,
        },
        {
          F_FullName: "",
        },
        {
          F_FullName: "",
        },
        {
          F_FullName: "",
        },
      ];
      let lists = {
        id: e.F_Id,
        name: "需求",
      };
      sessionStorage.setItem("record", JSON.stringify(menu));
      sessionStorage.setItem("page_id", 2);
      this.$router.push({ name: "Noticeparticulars", query: lists });
    },
    skiphdong(e) {
      var menu = [
        {
          F_FullName: "通知公告",
        },
        {
          F_FullName: e.hdmc,
        },
        {
          F_FullName: "",
        },
        {
          F_FullName: "",
        },
        {
          F_FullName: "",
        },
      ];
      let lists = {
        id: e.F_Id,
        name: "活动",
      };
      sessionStorage.setItem("record", JSON.stringify(menu));
      sessionStorage.setItem("page_id", 6);
      this.$router.push({ name: "Noticeparticulars", query: lists });
    },

  },
};
</script>

<style lang="scss" scoped>
.Notice {
  min-height: 300px;
  max-height: 400px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .lefts {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 12px;
    // max-height: 298px;
    width: 30%;

    .headline_lines {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .more {
        cursor: pointer;
        margin-bottom: 10px;

        .morespan {
          font-size: 16px;
          font-weight: bold;
          color: #5d207c;
        }
      }
    }

    .lines {
      margin-bottom: 10px;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #5d207c;

      span {
        margin-left: 10px;
      }
    }

    .line {
      width: 5px;
      height: 20px;
      background: #5d207c;
      border-radius: 5px;
      border: 1px solid #5d207c;
    }

    .muns {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .muns_list {
        width: 75%;
        display: flex;
        justify-content: left;
        align-items: center;
        cursor: pointer;

        .menus {
          background-color: #fbf2ff;
          color: #5d207c;
          padding: 5px;
          width: 100%;
          // height: 24px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #5d207c;
          border-bottom: 1px solid #5d207c;
          border-left: 1px solid #5d207c;
        }

        .menus_new {
          background-color: #5d207c;
          color: #ffffff;
          padding: 5px;
          width: 100%;
          font-size: 14px;
          // height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #5d207c;
          border-bottom: 1px solid #5d207c;
          border-left: 1px solid #5d207c;
        }

        .menus_news {
          background-color: #5d207c;
          color: #ffffff;
          padding: 5px;
          width: 68px;
          font-size: 14px;
          // height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #5d207c;
          border-bottom: 1px solid #5d207c;
          border-left: 1px solid #5d207c;
        }
      }

      .more {
        width: 10%;
        margin-bottom: 10px;
        cursor: pointer;

        .morespan {
          font-size: 16px;
          font-weight: bold;
          color: #5d207c;
        }
      }
    }

    .muns_center {
      min-height: 200px;

      ul {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;
          cursor: pointer;

          .Notice_title {
            width: 65%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 14px;
            letter-spacing: 1px;
            text-align: left;
          }

          .Notice_time {
            font-size: 14px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .muns .muns_list .menus:nth-child(4) {
      border-right: 1px solid #5d207c;
    }
  }
}

::v-deep .el-loading-spinner .path {
  // stroke: #4f40ff;
  stroke: #5d207c;
}
</style>