<template>
  <div class="resource">
    <div class="choose">
      <ul>
        <li v-for="(items, index) in park" :key="index"
          :class="activeName == items.F_Id ? 'subselection_new' : 'subselection'" @click="jump(items)">
          <span>{{ items.title }}</span>
        </li>
      </ul>
    </div>
    <div class="a0013-container" v-loading="Loading">
      <div class="a0013-con bgcolor" @click="jumps()">
        <div class="a0013-swiper">
          <el-carousel :interval="3000" type="card" height="420px" :autoplay="true" style="overflow-x: unset"
            indicator-position="none">
            <clas v-for="(item, i) in chooses" :key="i" :autoplay="true">
              <div class="imgBox">
                <!-- <img class="imgItem" v-if="(activeName =='145031258452c')" :src="item.url"  alt="" srcset="" /> -->
                <img class="imgItem" :src="'http://114.117.195.108:3000' + item.url" alt="" srcset="" />
              </div>
            </clas>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clas from "./clas.vue";
import { OrderService } from "../../../api/product";
export default {
  props: ["park"],
  components: {
    clas,
  },
  data() {
    return {
      activeName: '',
      activetitle: '',
      choose: [
        {
          name: "全部",
          id: 1,
        },
        {
          name: "高新企业",
          id: 2,
        },
      ],
      chooses: [
        {
          url: require("../../../assets/image/Park1.png"),
          id: 1,
        },
        {
          url: require("@/assets/image/Park1.png"),
          id: 2,
        },
        {
          url: require("@/assets/image/Park1.png"),
          id: 3,
        },
        {
          url: require("@/assets/image/Park1.png"),
          id: 4,
        },
        {
          url: require("@/assets/image/Park1.png"),
          id: 5,
        },
      ],
      Loading: false
    };
  },
  mounted() {
  },
  watch: {
    park(val) {
      if (val) {

        this.activeName = val[0].F_Id
        this.getmaker(val[0].F_Id)

      }
    }
  },
  methods: {
    jump(e) {
      this.activeName = e.F_Id;
      this.activetitle = e.title
      this.Loading = true

      this.getmaker(e.F_Id);
      // if (e.id != "145031258452c") {
      //   this.getmaker(e.id);

      // } else {
      //   this.chooses = [
      //     {
      //       url: require("@/assets/image/Park1.png"),
      //       id: 1,
      //     },
      //     {
      //       url: require("@/assets/image/Park1.png"),
      //       id: 2,
      //     },
      //     {
      //       url: require("@/assets/image/Park1.png"),
      //       id: 3,
      //     },
      //     {
      //       url: require("@/assets/image/Park1.png"),
      //       id: 4,
      //     },
      //     {
      //       url: require("@/assets/image/Park1.png"),
      //       id: 5,
      //     },
      //   ];
      setTimeout(() => {
        this.Loading = false
      }, 500)

      // }
    },
    getmaker(id) {
      OrderService.getfupark(id, 0, 20).then((res) => {
        this.center = res.data.list;
        this.chooses = JSON.parse(this.center[0].thumbnail);
     
        setTimeout(() => {
          this.Loading = false
        }, 500)

      });
    },
    jumps(items) {

      if (this.activeName != '145031258452c') {
        var menu = [
          {
            fullName: "园区资源",
          },
          {
            fullName: "",
          },
          {
            fullName: "",
          },
          {
            fullName: "",
          },
          {
            fullName: "",
          },
        ];
        menu[1].fullName = this.activetitle
        sessionStorage.setItem("record", JSON.stringify(menu));

        this.$router.push({ name: "Parkresources", query: { id: this.activeName } });
      }


    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-carousel__arrow--left {
  left: -14px !important;
}

::v-deep.el-carousel__arrow--right {
  right: -16px !important;
}

::v-deep .el-carousel__mask {
  background-color: #fff0 !important;
}

.resource {
  width: 80%;
  margin: 0 auto;
  background-color: #ffff;
  border-radius: 10px;
  padding: 20px 14px;
  margin-bottom: 31px;
  position: relative;

  .choose {
    background-color: #ffff;

    ul {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .subselection {
        background-color: #fbf2ff;
        color: #5d207c;
        width: 102px;
        height: 43px;
        margin: 0 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .subselection_new {
        background-color: #5d207c;
        color: #ffffff;
        width: 102px;
        height: 43px;
        padding: 0 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.a0013-container {
  min-height: 400px;
  margin-top: 75px;
  width: 100%;
  display: flex;
  justify-content: center;

  .a0013-con {
    margin: 0 auto;
    display: flex;
    justify-content: center;

    // width: 1200px;
    // padding-bottom: 50px;
    .a0013-tit {
      width: 240px;
      margin-bottom: 30px;

      img {
        width: 240px;
      }
    }

    .a0013-swiper {
      width: 1250px;
      height: 100%;

      .el-carousel__item {
        width: 560px;
        border-radius: 5px;
        padding: 10px;
        // background-color: #f1f6fe;
        // box-sizing: border-box;
      }

      .imgBox {
        position: relative;
        border-radius: 5px;
        width: 100%;

        .imgItem {
          width: 560px;
          border-radius: 5px;
          text-align: center;
          height: 400px;
          background-size: cover;
        }
      }
    }
  }
}

.more {
  width: 10%;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 0;
}

::v-deep .el-loading-spinner .path {
  // stroke: #4f40ff;
  stroke: #5d207c;
}
</style>