<template>
  <div id="app">
    <top_tababr></top_tababr>
    <router-view />
    <bottom></bottom>
  </div>
</template>
<script>
import top_tababr from "@/components/top_tababr.vue";
import bottom from "@/components/bottom.vue";
import { OrderService } from "./api/register";
export default {
  name: "HomeView",
  components: {
    top_tababr,
    bottom,
  },
  data() {
    return {};
  },
  created() {},

  mounted() {
    var browerWidth = window.innerWidth; //浏览器可视宽度
    var baseWidth = 1920; //设计稿宽度
    var zoomValue = browerWidth / baseWidth; //缩放比例计算
    if (document.getElementById("mainContainer")) {
      document.getElementById("mainContainer").style.transform = "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
    }
    window.onresize = function () {
      console.log(document.getElementById("mainContainer"));
      //窗口尺寸变化时，重新计算和缩放
      browerWidth = window.innerWidth;
      zoomValue = browerWidth / baseWidth;
      document.getElementById("mainContainer").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
    };
  },
  methods: {
    // 获取token
    gettoken() {
      var origin = "password";
      let code = "";
      let timestamp = "";
      let client_id = "admin";
      let client_secret = "123456";
      let scope = "all";
      sessionStorage.setItem("usermima", JSON.stringify(this.Account));
      let user = "admin";
      let password = md5("admin@123");
      OrderService.getlogin(user, password, origin, code, timestamp, client_id, client_secret, scope).then(res => {
        sessionStorage.setItem("token", res.data.token);
      });
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  height: 100%;
  // overflow-x :hidden
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
