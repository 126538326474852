
import axios from 'axios'

import { Message } from 'element-ui';

const service = axios.create({

  baseURL: 'http://114.117.195.108:3000',
  // 超时时间 单位是ms，这里设置了5s的超时时间
  timeout: 6 * 1000
})
// 2.请求拦截器
// service.interceptors.request.use(config => {
//   //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
//    config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
//    config.headers = {
//     'access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiJhZG1pbiIsIkFjY291bnQiOiJhZG1pbiIsIlVzZXJOYW1lIjoi566h55CG5ZGYIiwiQWRtaW5pc3RyYXRvciI6MSwiVGVuYW50SWQiOiJoYWdvbmdkYSIsIlRlbmFudERiTmFtZSI6ImhhZ29uZ2RhIiwiU2luZ2xlTG9naW4iOjEsImlhdCI6MTY3MzQzMTAwNCwibmJmIjoxNjczNDMxMDA0LCJleHAiOjE2NzM0ODUwMDQsImlzcyI6Indyc3NvZnQiLCJhdWQiOiJ3cnNzb2Z0In0.Th3FahlQ2h3rfAg7JEmeStvsT0u84kuXlE_16jGpmRc',
//      'Content-Type':'application/json' ,//配置请求头
//     //  'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiJhZG1pbiIsIkFjY291bnQiOiJhZG1pbiIsIlVzZXJOYW1lIjoi566h55CG5ZGYIiwiQWRtaW5pc3RyYXRvciI6MSwiVGVuYW50SWQiOiJoYWdvbmdkYSIsIlRlbmFudERiTmFtZSI6ImhhZ29uZ2RhIiwiU2luZ2xlTG9naW4iOjEsImlhdCI6MTY3MzMxNjMzOCwibmJmIjoxNjczMzE2MzM4LCJleHAiOjE2NzMzNzAzMzgsImlzcyI6Indyc3NvZnQiLCJhdWQiOiJ3cnNzb2Z0In0.x9llHfoDXbDRoSnSLpBdz4LW1Bmh7s1JvgvwAC4P5w8'
//    }
//   return config
// }, error => {
//   Promise.reject(error)
// })
service.interceptors.request.use(config => {
  // 为请求头添加x-access-token字段为服务端返回的token

  config.headers['YmDate'] = '2024'
  
  config.headers['Authorization'] ="hagongda::88b34d98822d4315805c41942a7ee248"
  // config.headers['Authorization'] = sessionStorage.getItem('token') || "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiJhZG1pbiIsIkFjY291bnQiOiJhZG1pbiIsIlVzZXJOYW1lIjoi566h55CG5ZGYIiwiQWRtaW5pc3RyYXRvciI6MSwiVGVuYW50SWQiOiJoYWdvbmdkYSIsIlRlbmFudERiTmFtZSI6ImhhZ29uZ2RhIiwiU2luZ2xlTG9naW4iOjEsImlhdCI6MTY3MzgzODE2MywibmJmIjoxNjczODM4MTYzLCJleHAiOjE2NzM4OTIxNjMsImlzcyI6Indyc3NvZnQiLCJhdWQiOiJ3cnNzb2Z0In0.oWzospQb6OIJ1gAZV7Lx2v3X2Wkxac-Nzx2v3FLSBwg"
  // return config是固定用法 必须有返回值
  return config
})

// 3.响应拦截器
service.interceptors.response.use(response => {
  //接收到响应数据并成功后的一些共有的处理，关闭loading等
  let res = response.data;
  // 如果是返回文件
  if (response.config.responseType === 'blob') {
    return res;
  }
  // 兼容服务器返回的字符串数据
  if (typeof res === 'string') {
    res = res ? JSON.parse(res) : res
  }

  return res;
}, error => {
  /***** 接收到异常响应的处理开始 *****/
  if (error && error.response) {
    // 1.公共错误处理
    // 2.根据响应码具体处理
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break;
      case 401:
        error.message = '未授权，请重新登录'
        break;
      case 403:
        error.message = '拒绝访问'
        break;
      case 404:
        error.message = '请求错误,未找到该资源'
        window.location.href = "/NotFound"
        break;
      case 405:
        error.message = '请求方法未允许'
        break;
      case 408:
        error.message = '请求超时'
        break;
      case 501:
        error.message = '网络未实现'
        break;
      case 502:
        error.message = '网络错误'
        break;
      case 503:
        error.message = '服务不可用'
        break;
      case 504:
        error.message = '网络超时'
        break;
      default:
      // error.message = `连接错误${error.response.status}`
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
      // Message.error('服务器响应超时，请刷新当前页')
    }
    // error.message = '连接服务器失败'
  }

  // Message.error(error.message)
  /***** 处理结束 *****/
  //如果不需要错误处理，以上的处理过程都可省略
  return Promise.resolve(error.response)
})
//4.导入文件
export default service