import service from "../utils/https";
export const OrderService = {
  // 企业注册
  getregister(data

  ) {
    return service.post('/api/permission/Users', {
      data
    })
  },
  // 提交企业信息
  getregister_information(data, flowId, status, ) {
    return service.post('/api/visualdev/OnlineDev/377307316434790853', {
      data,
      flowId,
      status
    })
  },
  // 登录
  getlogin(account, password,jnpf_ticket, origin, code, timestamp, grant_type) {
    return service.post('/api/oauth/Login?n=1693132377', {
      // return service.post('/api/oauth/Login', {
      account,
      password,
      jnpf_ticket,
      origin,
      code,
      timestamp,
      grant_type

    })
  },
  // 获取用户信息
  getCurrentUser(time) {
    return service.get('/api/oauth/CurrentUser?n=' + time, {


    })
  },

}
