<template>
  <div class="Scientific_research">
    <div class="choose">
      <ul>
        <li
          v-for="(items, index) in Servicekej"
          :key="index"
          :class="activeName == items.id ? 'subselection_new' : 'subselection'"
          @click="jump(items)"
        >
          <span>{{ items.fullName }}</span>
        </li>
      </ul>
    </div>
    <!-- <div class="more" @click="getmore">
      <img src="@/assets/image/more.png" alt="" />
    </div> -->
    <div class="resources">
      <div class="resource_left">
        <div class="carousel">
          <el-carousel height="280px" indicator-position="none">
            <el-carousel-item v-for="(i, indexi) in resource" :key="indexi">
              <div class="images">
                <img :src=" 'http://114.117.195.108:3000' + i.url" class="carousel_img" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="Article_content">
            
            <img :src="'http://114.117.195.108:3000' + itemimg.url" v-for="(itemimg,indeximg) in Article_content1 " :key="indeximg" alt="" />
            <!-- <img :src="'http://114.117.195.108:3000' + this.bannerlistkeji.lbimage_url[1].url"  alt="" /> -->
          </div>
        </div>
      </div>
      <div class="resource_right">
        <div class="article" v-loading="Loading" >
          <ul>
            <li
              v-for="(i, indexi) in Article_list"
              :key="indexi"
              @click="jumplist(i)"
            >
              <div class="Service">
                <img
                  v-if="i.zyslt != undefined"
                  :src="'http://114.117.195.108:3000' + i.zyslt[0].url"
                  class="Service_img"
                  alt=""
                />
                <img
                  v-if="i.zyslt == undefined"
                  src="../../../assets/image/datewithlove2.png"
                  class="Service_img"
                  alt=""
                />
                <div class="Service_center">
                  <span class="Service_span">{{ i.zymc }}</span>
                  <p class="Service_p">类型：{{ i.zylx }}</p>
                  <p class="Service_p">姓名：{{ i.lxr }}</p>
                  <p class="Service_p">周期：{{ i.fwzq }}</p>
                  <p class="Service_p">地址：{{ i.lxdz }}</p>
                </div>
              </div>
            </li>
          </ul>
          <el-empty  v-if="Article_list.length == 0"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OrderService } from "../../../api/product";
export default {
  props: ["Servicekej"],
  data() {
    return {
      activeName: "14503125845keji",
      choose: [
        {
          name: "全部",
          id: 1,
        },
        {
          name: "高新企业",
          id: 2,
        },
        {
          name: "高新企业",
          id: 3,
        },
        {
          name: "高新企业",
          id: 4,
        },
        {
          name: "高新企业",
          id: 5,
        },
        {
          name: "高新企业",
          id: 6,
        },
        {
          name: "高新企业",
          id: 7,
        },
        {
          name: "高新企业",
          id: 8,
        },
      ],
      resource: [
        {
          icon: require("@/assets/image/Service1.png"),
        },
        {
          icon: require("@/assets/image/Service2.png"),
        },
      ],
      Article_list: [
        {
          name: "文字占位",
          center:
            "文字占位文字占位文字占位文字占位文字占位文字占位文字占位文字占位",
          icon: require("@/assets/image/resource1.png"),
        },
        {
          name: "文字占位",
          center:
            "文字占位文字占位文字占位文字占位文字占位文字占位文字占位文字占位",
          icon: require("@/assets/image/resource1.png"),
        },
        {
          name: "文字占位",
          center:
            "文字占位文字占位文字占位文字占位文字占位文字占位文字占位文字占位",
          icon: require("@/assets/image/resource1.png"),
        },
      ],
      bannerlistkeji:[],
      queryJson: {},
      result_list: [],
      Article_content1:'',
      Article_content2:'',
      Loading: false,
    };
  },
  mounted() {
    this.getbannerkeji();
    this.getServicelist();
  },
  methods: {
    jump(e) {
      this.$set(this.queryJson, "zylx", e.id);
      this.activeName = e.id;
      if (e.id == "14503125845keji") {
        this.queryJson = {};
      }else if (e.id == "0") {
        this.getmore()
        return false

      }
      this.getServicelist();
    },
    // 获取科研商品列表
    getServicelist() {
      this.Loading = true;
      var currentPage = 1;
      var pageSize = 6;
      let sort, sidx;
      let menuId = "393633233536864325";
      sort = "desc";
      let queryJsonnew = JSON.stringify(this.queryJson);
      OrderService.getScientific_research(
        currentPage,
        pageSize,
        sort,
        sidx,
        menuId,
        queryJsonnew
      ).then((res) => {
        if (res.data.list != []) {
          this.Article_list = res.data.list;
          this.Loading = false;
        }
      });
    },
    jumplist(e) {
      this.result_list = [
        {
          fullName: "技术服务资源",
        },
        {
          fullName: e.zymc,
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
      ];
      sessionStorage.setItem("screening", JSON.stringify(this.result_list));
      var list ={
        id:e.id,
        name:'技术服务资源'
      }
      this.$router.push({ name: "product_details", query:list });
    },
    getmore() {
      this.result_list = [
        {
          fullName: "技术服务资源",
        },
        {
          fullName: '',
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
      ];
      sessionStorage.setItem("screening", JSON.stringify(this.result_list));
      var list ={
        name:'技术服务资源'
      }
      this.$router.push({ name: "Service_resource",query:list });
    },
      // 获取科技轮播图
      getbannerkeji() {
      let currentPage = "1";
      let pageSize = "20";
      let sort = "desc";
      let sidx = "";
      let menuId = "396102756525465605";
      let queryJson = "";
      OrderService.getbannersciencelist(
        currentPage,
        pageSize,
        sort,
        sidx,
        menuId,
        queryJson
      ).then((res) => {
        if (res.code == 200) {
          
          this.bannerlistkeji = res.data.list[0];
        }
      });
    },
  },
  watch:{
    bannerlistkeji(val){

      this.resource = val.lbimage_url
      this.Article_content1 =val.jzimage_url
    }
  }
};
</script>

<style lang="scss" scoped>
.Scientific_research {
  width: 80%;
  margin: 0 auto;
  background-color: #ffff;
  border-radius: 10px;
  padding: 20px 14px;
  position: relative;
  .choose {
    background-color: #ffff;
    ul {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .subselection {
        background-color: #fbf2ff;
        color: #5d207c;
        padding: 0 10px;
        width: 102px;
        height: 43px;
        margin: 0 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .subselection_new {
        background-color: #5d207c;
        color: #ffffff;
        padding: 0 10px;
        width: 102px;
        height: 43px;
        margin: 0 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .resources {
    width: 98%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // align-items: center;

    .resource_left {
      margin-top: 20px;
      width: 30%;
      height: 443px;

      .carousel {
        width: 100%;
        .images {
          width: 100%;
          //   height: 100%;
          // background-color: red;
          background-size: 100%;
          // background-image: url('@/assets/image/resource1.png');
        }
        .carousel_img {
          border-radius: 10px;
          width: 100%;
          height: 280px;
          background-size: 100%;
        }
      }
      .Article_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        img {
          border-radius: 10px;
          width: 48%;
          height: 134px;
        }
      }
    }
    .resource_right {
      margin-top: 20px;
      width: 69%;
      height: 443px;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      .article {
        width: 100%;
      }
      ul {
        li {
          // margin-bottom: 10px;
          .Service {
            width: 50%;
            display: flex;
            justify-content: left;
            float: left;
            margin-bottom: 14px;
            // align-items: center;
            .Service_img {
              width: 170px;
              height: 134px;
              background-size: 100%;
              border-radius: 10px;
            }
            .Service_center {
              width: 55%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 14px;
              letter-spacing: 1px;
              text-align: left;
              margin-left: 20px;
            }
            .Service_span {
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 1px;
              margin-bottom: 10px;
            }
            .Service_p {
              width: 80%;
              font-size: 12px;
              letter-spacing: 1px;
              padding: 0;
              margin: 0;
              line-height: 27px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
.more {
  width: 10%;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 30px;
  right: 0;
}
::v-deep .el-loading-spinner .path {
  // stroke: #4f40ff;
  stroke: #5d207c;
}
</style>