import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    // 通知
    path: '/announcement',
    name: 'announcement',
    component: () => import( '../views/announcement/announcement.vue')
  },
  {
    // 商品列表 --服务资源
    path: '/Service_resource',
    name: 'Service_resource',
    component: () => import( '../views/Service_resource/Service_resource.vue')
  },
  {
    // 企业资源列表
    path: '/Qiye_resource',
    name: 'Qiye_resource',
    component: () => import( '../views/Service_resource/Qiye_resource.vue')
  },
  {
    // 注册
    path: '/register',
    name: 'register',
    component: () => import( '../views/register/register.vue')
  },
  {
    // 商品详情
    path: '/product_details',
    name: 'product_details',
    component: () => import( '../views/product_details/product_details.vue')
  },
  {
    // 园区资源
    path: '/Parkresources',
    name: 'Parkresources',
    component: () => import( '../views/Parkresources/Parkresources.vue')
  },
  {
    //通知详情
    path: '/Noticeparticulars',
    name: 'Noticeparticulars',
    component: () => import( '../views/Noticeparticulars/Noticeparticulars.vue')
  },
  {
    //企业详情
    path: '/enterprise_details',
    name: 'enterprise_details',
    component: () => import( '../views/enterprise_details/enterprise_details.vue')
  },
  {
    //活动通知
    path: '/Activity',
    name: 'Activity',
    component: () => import( '../views/Activity/Activity.vue')
  },
]
//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
const router = new VueRouter({
  routes,
   //配置滚动行为，跳转到新的路由界面滚动条的位置
   scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
