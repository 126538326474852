<template>
  <div class="CheckDialog">
    <el-dialog
      title="入驻申请"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="from" v-if="this.showiss == false">
        <el-form
          :model="Account"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
        >
          <el-form-item label="联系人：" prop="user">
            <el-input
              v-model="Account.user"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="iphone">
            <el-input
              v-model="Account.iphone"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" prop="mailbox">
            <el-input
              v-model="Account.mailbox"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="单位名称：" prop="danwei">
            <el-input
              v-model="Account.danwei"
              placeholder="请输入单位名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button class="cancel" @click="handleClose">取消</el-button>
            <el-button
              class="submit"
              :loading="loading"
              @click="submit('ruleForm')"
              >确 定</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="succeedspan" v-if="this.showiss == true">
        <div style="text-align: center">
          <img
            class="succeedimg"
            src="../assets/image/succeed.png"
            alt=""
          />
          <div class="succeed">提交成功，请等待回应</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { OrderService } from "../api/product";
export default {
  props: ["show", "yuanqid"],
  data() {
    return {
      dialogVisible: false,
      form: [
        {
          label: "联系人：",
          placeholder: "请输入联系人姓名",
          name: "user",
        },
        {
          label: "手机号码：",
          placeholder: "请输入联系人手机号码",
          name: "iphone",
        },
        {
          label: "邮箱：",
          placeholder: "请输入联系邮箱",
          name: "mailbox",
        },
        {
          label: "单位名称：",
          placeholder: "请输入所属单位名称",
          name: "danwei",
        },
      ],
      rules: {
        user: [{ required: true, message: "请输入名称", trigger: "blur" }],
        iphone: [
          { required: true, message: "手机号不能为空" },
          // { type: "number", message: "手机号必须为数字值" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        mailbox: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        danwei: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
      },
      loading: false,
      Account: {
        user: "",
        iphone: "",
        mailbox: "",
        danwei: "",
      },
      showiss: false,
    };
  },
  mounted() {
    this.dialogVisible = this.show;
  },
  methods: {
    handleClose(done) {
      (this.loading = false), (this.dialogVisible = false);
      this.$emit("update:show", false);
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          
          this.loading = true;
          let time = parseInt(new Date().getTime()) + "";
     
          var userinfor = JSON.parse(sessionStorage.getItem("usermima"));
          var tijiao = {
            yqid: this.yuanqid,
            yuyueshijian: time,
            yuyueren: this.Account.user,
            yuyuerentel: this.Account.iphone,
          };
          var status = 0;
          var flowId = "383492344978116613";
          OrderService.getregister_information(JSON.stringify(tijiao), flowId, status).then(
            (res) => {

              if (res.code == 200) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                this.loading = false;
                this.showiss = true;
                setTimeout(() => {
                  this.handleClose();
                }, 4500);
              } else {
                this.loging = false;
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            }
          );
        } else {
        
          return false;
        }
      });
    },
  },
  watch: {
    show(val) {
      this.dialogVisible = this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel {
  padding: 10px 45px;
  color: #5d207c;
  border-color: #5d207c;
}
.succeed {
  margin-top: 10px;
  color: #5d207c;
}
.cancel:hover {
  background-color: #d9d4db;
}
.submit {
  padding: 10px 45px;
  color: #fff;
  background-color: #5d207c;
  border-color: #5d207c;
}
.succeedspan {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.succeedimg {
  width: 60px;
  height: 60px;
}
::v-deep .el-dialog {
  border-radius: 10px;
  border: 0px solid #dadada00;
}
::v-deep .el-dialog__body {
  padding: 20px 30px;
}
::v-deep .el-dialog__header {
  padding: 10px 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;

  .el-dialog__title {
    color: #5d207c !important;
  }
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
::v-deep .el-dialog__headerbtn {
  position: inherit;
}
::v-deep .el-form-item__label {
  width: 90px !important;
  text-align: center !important;
  padding: 0;
}
::v-deep .el-form-item__content {
  margin-left: 90px !important;
 
}
::v-deep .el-input__inner {
  height: 35px;
  border-radius: 5px;
  border: 1px solid #5d207c;
}
::v-deep .el-dialog__headerbtn:hover .el-dialog__close{
  color: #5d207c;
}
</style>