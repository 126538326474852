<template>
  <div class="resource">
    <div class="choose">
      <ul>
        <li
          v-for="(items, index) in Service"
          :key="index"
          :class="
            activeName == items.F_Id ? 'subselection_new' : 'subselection'
          "
          @click="jump(items)"
        >
          <span>{{ items.F_FullName }}</span>
        </li>
      </ul>
    </div>
    <div class="choose_two">
      <ul>
        <li
         
          :class="
            activeNameTwo == '' ? 'subselection_new' : 'subselection'
          "
          @click="handleTwoTypeAll()"
        >
          <span>全部</span>
        </li>
        <li
          v-for="(items, index) in ServiceTwoTpye"
          :key="index"
          :class="
            activeNameTwo == items.F_Id ? 'subselection_new' : 'subselection'
          "
          @click="handleTwoType(items)"
        >
          <span>{{ items.F_FullName }}</span>
        </li>
      </ul>
    </div>
    <!-- <div class="more" @click="getmore">
      <img src="@/assets/image/more.png" alt="" />
    </div> -->
    <div class="resources">
      <div class="resource_left">
        <div class="carousel">
          <el-carousel height="280px" indicator-position="none">
            <el-carousel-item v-for="(i, indexi) in resource" :key="indexi">
              <div class="images">
                <a :href="i.link_url">
                  <img
                    v-if="i[0].url"
                    :src="'http://114.117.195.108:3000' + i[0].url"
                    class="carousel_img"
                    alt=""
                  />
                </a>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="Article_content">
            <a
              :href="items.link_url"
              v-for="(items, indexs) in resources"
              :key="indexs"
            >
              <img
                v-if="items[0].url"
                :src="'http://114.117.195.108:3000' + items[0].url"
                :key="indexs"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="resource_right">
        <div class="article" v-loading="Loading">
          <ul>
            <li
              v-for="(i, indexi) in Article_list"
              :key="indexi"
              @click="jumplist(i)"
            >
              <div class="Service">
                <!-- <img
                  v-if="!(i.zyslt && i.zyslt.length && i.zyslt[0].url)"
                  :src="i?.imgRandomNumUrl"
                  class="Service_img"
                  alt=""
                />
                <img
                  v-else
                  :src="'http://114.117.195.108:3000' + i.zyslt[0].url"
                  class="Service_img"
                  alt=""
                /> -->
                <img
                  v-if="i.twjs"
                  :src="i?.imgRandomNumUrl"
                  class="Service_img"
                  alt=""
                />
                <div class="Service_center">
                  <span class="Service_span">{{ i.zymc }}</span>
                  <p class="Service_p">类型：{{ i.zylx }}</p>
                  <p class="Service_p">姓名：{{ i.lxr }}</p>
                  <p class="Service_p">周期：{{ i.fwzq }}</p>
                  <p class="Service_p">地址：{{ i.lxdz }}</p>
                </div>
              </div>
            </li>
          </ul>
          <el-empty v-if="Article_list.length == 0"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OrderService } from "../../../api/product";
export default {
  props: ["Service"],
  data() {
    return {
      activeName: "",
      activeNameTwo: "",
      ServiceTwoTpye: [],
      choose: [
        {
          name: "全部",
          id: 1,
        },
        {
          name: "高新企业",
          id: 2,
        },
        {
          name: "高新企业",
          id: 3,
        },
        {
          name: "高新企业",
          id: 4,
        },
     
      ],
      resource: [],
      bannerlistfuwu: [],
      resources: [],
      Article_list: [
        {
          name: "文字占位",
          center:
            "文字占位文字占位文字占位文字占位文字占位文字占位文字占位文字占位",
          icon: require("@/assets/image/resource1.png"),
        },
        {
          name: "文字占位",
          center:
            "文字占位文字占位文字占位文字占位文字占位文字占位文字占位文字占位",
          icon: require("@/assets/image/resource1.png"),
        },
        {
          name: "文字占位",
          center:
            "文字占位文字占位文字占位文字占位文字占位文字占位文字占位文字占位",
          icon: require("@/assets/image/resource1.png"),
        },
      ],
      queryJson: {},
      result_list: [],
      Loading: false,
    };
  },
  mounted() {
    this.getbannerfuwu();
    this.getServicelist();
    this.getserviceThree();
    // this.getserviceTeacher();
  },
  methods: {
    jump(e) {
      this.$set(this.queryJson, "zylx", e.F_Id);
      this.activeName = e.F_Id;
      if (e.F_FullName == "第三方服务机构") {
       
        this.getserviceThree();
        this.activeNameTwo = '';
      } else if (e.F_FullName == "创业导师") {
      
        this.getserviceTeacher();
        this.activeNameTwo = '';
      } else if (e.F_FullName == "更多") {
        this.$router.push({
          name: "Service_resource",
          query: { type: "全部" },
        });
      } else {
        this.getServicetypelist(e.F_Id);
      }
      // if (e.id == "14503125845zy") {
      //   this.queryJson = {};
      // } else if (e.id == "0") {
      //   this.getmore();
      //   return false;
      // }
    },
    // 查询全部列表
    handleTwoTypeAll(){
      this.activeNameTwo = '';
    },
    handleTwoType(e) {
      this.activeNameTwo = e.F_Id;
      this.getServicetypelist(e.F_Id);
    },
    // 获取第三方机构分类
    getserviceThree() {
      OrderService.getType("461902633599238405", 0, 20).then((res) => {
        // console.log("第三方机构 res", res);
        this.ServiceTwoTpye = res.data.list;
      });
    },
    // 获取导师的分类
    getserviceTeacher() {
      OrderService.getType("469513229341565637", 0, 20).then((res) => {
        // console.log("导师 res", res);
        this.ServiceTwoTpye = res.data.list;
      });
    },
    // 获取资源列表--全部
    getServicelist() {
      let kkeyy = "";
      let offs = 0;
      let pages = 20;
      OrderService.getServiceList(kkeyy, offs, pages).then((res) => {
        if (res.data.list != []) {
          let list = res.data.list;
          list.map((i) => {
            if (i.zyslt != undefined) {
              i.zyslt = JSON.parse(i.zyslt);
            } else {
              i.zyslt = [];
            }
            if (i.zytx != undefined) {
              i.zytx = JSON.parse(i.zytx);
            } else {
              i.zytx = [];
            }

            let imgRandomNum = Number(i.F_Id.slice(-1));
            if (imgRandomNum < 5) {
              imgRandomNum += 9;
            } else if (imgRandomNum < 9) {
              imgRandomNum += 4;
            }
            i.imgRandomNumUrl = require("@/assets/image/random_ziyuan/" +
              imgRandomNum +
              ".png");
          });

          this.Article_list = list;

          this.Loading = false;
        }
      });
    },
    // 获取
    // getType
    // 获取商品列表--分类
    getServicetypelist(id) {
      let zylx = id;
      let offs = 0;
      let pages = 20;
      OrderService.getServiceTypeList(zylx, offs, pages).then((res) => {
        if (res.data.list != []) {
          let list = res.data.list;
          list.map((i) => {
            if (i.zyslt != undefined) {
              i.zyslt = JSON.parse(i.zyslt);
            } else {
              i.zyslt = [];
            }
            if (i.zytx != undefined) {
              i.zytx = JSON.parse(i.zytx);
            } else {
              i.zytx = [];
            }
          });
          this.Article_list = list;
          this.Loading = false;
        }
      });
    },
    jumplist(e) {
      this.result_list = [
        {
          fullName: "服务资源",
        },
        {
          fullName: e.zymc,
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
      ];
      sessionStorage.setItem("screening", JSON.stringify(this.result_list));

      var list = {
        id: e.F_Id,
        name: "服务资源",
      };
      console.log("jumplist,query :", list);
      this.$router.push({ name: "product_details", query: list });
    },
    // getmore() {
    //   this.result_list = [
    //     {
    //       fullName: "服务资源",
    //     },
    //     {
    //       fullName: "",
    //     },
    //     {
    //       fullName: "",
    //     },
    //     {
    //       fullName: "",
    //     },
    //     {
    //       fullName: "",
    //     },
    //   ];
    //   sessionStorage.setItem("screening", JSON.stringify(this.result_list));
    //   var lists = {
    //     type: "全部",
    //   };
    //   this.$router.push({ name: "Service_resource", query: lists });
    // },
    // 获取服务轮播图
    getbannerfuwu() {
      let kkeyy = "";
      let offs = 0;
      let pages = 10;

      OrderService.getbannerservicelist(kkeyy, offs, pages).then((res) => {
        if (res.code == 200) {
          this.bannerlistfuwu = res.data.list;
        }
      });
    },
  },
  watch: {
    bannerlistfuwu(val) {
      let lblist = [];
      let jzlist = [];

      val.map((i) => {
        i.lbimage_url = JSON.parse(i.lbimage_url);
        i.jzimage_url = JSON.parse(i.jzimage_url);
        if (i.lbimage_url.length > 0) {
          i.lbimage_url.link_url = i.link_url;
          lblist.push(i.lbimage_url);
        }
        if (i.jzimage_url.length > 0) {
          i.jzimage_url.link_url = i.link_url;
          jzlist.push(i.jzimage_url);
        }
      });
      this.resource = lblist;
      this.resources = jzlist;
    },
    Service(val) {
      this.activeName = val && val[0].F_Id;
      // this.getserviceThree();
      
    },
    // ServiceTwoTpye(val) {
    //   if (val) {
    //     this.activeNameTwo = val && val[0].F_Id;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.resource {
  width: 80%;
  margin: 0 auto;
  background-color: #ffff;
  border-radius: 10px;
  padding: 20px 14px;
  position: relative;

  .choose {
    background-color: #ffff;

    ul {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .subselection {
        background-color: #fbf2ff;
        color: #5d207c;
        padding: 0 10px;
        width: 102px;
        height: 43px;
        margin: 0 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .subselection_new {
        background-color: #5d207c;
        color: #ffffff;
        padding: 0 10px;
        width: 102px;
        height: 43px;
        margin: 0 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .choose_two {
    background-color: #ffff;

    ul {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      .subselection {
        background-color: #fbf2ff;
        color: #5d207c;
        min-width: 35px;
        padding: 0 15px;
        height: 30px;
        margin: 0 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .subselection_new {
        background-color: #5d207c;
        color: #ffffff;
        min-width: 35px;
        padding: 0 15px;
        height: 30px;
        margin: 0 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .resources {
    width: 98%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // align-items: center;

    .resource_left {
      margin-top: 20px;
      width: 30%;
      height: 443px;

      .carousel {
        width: 100%;

        .images {
          width: 100%;
          //   height: 100%;
          // background-color: red;
          background-size: 100%;
          // background-image: url('@/assets/image/resource1.png');
        }

        .carousel_img {
          border-radius: 10px;
          width: 100%;
          height: 280px;
          background-size: 100%;
        }
      }

      .Article_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        a {
          width: 48%;
          height: 134px;
        }

        img {
          border-radius: 10px;
          width: 100%;
          height: 134px;
        }
      }
    }

    .resource_right {
      margin-top: 20px;
      width: 69%;
      height: 443px;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;

      .article {
        width: 100%;
      }

      ul {
        li {
          // margin-bottom: 10px;
          .Service {
            width: 50%;
            display: flex;
            justify-content: left;
            float: left;
            margin-bottom: 14px;

            // align-items: center;
            .Service_img {
              width: 170px;
              height: 134px;
              background-size: 100%;
              border-radius: 10px;
            }

            .Service_center {
              width: 55%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 14px;
              letter-spacing: 1px;
              text-align: left;
              margin-left: 20px;
            }

            .Service_span {
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 1px;
              margin-bottom: 10px;
            }

            .Service_p {
              width: 80%;
              font-size: 12px;
              letter-spacing: 1px;
              padding: 0;
              margin: 0;
              line-height: 27px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

.more {
  width: 10%;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 30px;
  right: 0;
}

::v-deep .el-loading-spinner .path {
  // stroke: #4f40ff;
  stroke: #5d207c;
}
</style>
