<template>
  <div class="Navigation">
    <div class="classification">
      <div class="classification_center" v-if="this.judgment == 'home'">
        <img
          class="classification_img"
          src="../assets/image/classification.png"
          alt=""
        />
        <span>分类</span>
      </div>
    </div>
    <div class="navigations">
      <ul class="navigation_list">
        <li
          v-for="(item, index) in tabbar"
          :key="index"
          @click="jump(item)"
          @mouseover="overItem(item)"
          @mouseleave="removeActive($event)"
          :class="
            item.id == isactivebox ? 'navigation_menunew' : 'navigation_menu'
          "
        >
          <span :class="{ atcre: item.id == isactivebox }">{{
            item.name
          }}</span>
          <div class="submenu">
            <ul
              v-if="item.chlid != ''"
              :class="
                item.id == isactive ? 'submenu_appear' : 'submenu_appears'
              "
            >
              <li v-for="(items, indexs) in item.chlid" :key="indexs">
                <div @click="jumps(items)" class="yuans_span">
                  {{ items.title }}
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { OrderService } from "../api/product";
export default {
  data() {
    return {
      defaultTabbar: [
        {
          name: "首页",
          id: 1,
          chlid: "",
        },
        {
          name: "公告",
          id: 2,
          chlid: "",
        },
        {
          name: "服务资源",
          id: 3,
          chlid: "",
        },
        {
          name: "企业入驻",
          id: 4,
          chlid: [],
        },
        {
          name: "园区资源",
          id: 5,
          chlid: "",
        },
        {
          name: "活动报名",
          id: 6,
          chlid: "",
        },
      ],
      tabbar: [
        {
          name: "首页",
          id: 1,
          chlid: "",
        },
        {
          name: "公告",
          id: 2,
          chlid: "",
        },
        {
          name: "服务资源",
          id: 3,
          chlid: "",
        },
        {
          name: "企业入驻",
          id: 4,
          chlid: [],
        },
        {
          name: "园区资源",
          id: 5,
          chlid: "",
        },
        {
          name: "活动报名",
          id: 6,
          chlid: "",
        },
      ],
      park: {},
      isactivebox: sessionStorage.getItem("page_id") || 1,
      result_list: [
        {
          fullName: "服务资源",
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
      ],
      isactive: "",
      isactives: "",
      judgment: sessionStorage.getItem("judgment"),
      activeName: "392928103262898565",
    };
  },
  created() {
    sessionStorage.setItem("judgment", "home");
  },
  mounted() {
    this.getmunsList();
    this.judgment = sessionStorage.getItem("judgment");
  },
  methods: {
    overItem(item) {
      this.isactivebox = item.id;
      this.isactive = item.id;
    },
    removeActive() {
      this.isactivebox = sessionStorage.getItem("page_id") || 1;
      this.isactive = "";
    },
    jump(item) {
      this.isactive = item.id;
      this.isactives = item.id;
      sessionStorage.setItem("page_id", item.id); //存储当前页面

      switch (item.id) {
        case 0:
          this.$router.push({ name: "register" });
          break;
        case 1:
          this.$router.push({ name: "home" });
          break;
        case 2:
          this.$router.push({ name: "announcement" });
          break;
        case 3:
          sessionStorage.setItem("screening", JSON.stringify(this.result_list));
          this.$router.push({
            name: "Service_resource",
            query: { type: "全部" },
          });
          // }
          break;
        case 4:
          let resultlist = [
            {
              fullName: "企业入驻",
            },
            {
              fullName: "",
            },
          ];

          sessionStorage.setItem("screening", JSON.stringify(resultlist));
          // if (this.$route.query.name == undefined) {
          this.$router.push({
            name: "Qiye_resource",
            query: { type: "全部" },
          });
          // }
          break;
        case 5:
          this.$router.push({ name: "Parkresources", query: { id: "" } });
          break;
        case 6:
          this.$router.push({ name: "Activity" });
          break;

        default:
          break;
      }
    },
    jumps(items) {
      var menu = [
        {
          fullName: "园区资源",
        },
        {
          fullName: "",
        },
        {
          fullName: "",
        },
      ];
      menu[1].fullName = items.title;

      sessionStorage.setItem("record", JSON.stringify(menu));
      this.$router.push({ name: "Parkresources", query: { id: items.id } });
    },
    // 获取菜单
    getmunsList() {
      // OrderService.getmuns().then((res) => {
      //   this.park = res.data.list;
      //   // this.tabbar[3].chlid = this.park;
      // });
    },
  },
  watch: {
    $route: {
      handler(val, oldval) {
        switch (val.name) {
          case "home":
            var tabbarnew = this.defaultTabbar;
            // var tabbarnew = [
            //   {
            //     name: "首页",
            //     id: 1,
            //     chlid: "",
            //   },
            //   {
            //     name: "公告",
            //     id: 2,
            //     chlid: "",
            //   },
            //   {
            //     name: "服务资源",
            //     id: 3,
            //     chlid: "",
            //   },
            //   {
            //     name: "园区资源",
            //     id: 4,
            //     chlid: [
            //       // {
            //       //   names: "新型开发区",
            //       //   id: 4 - 1,
            //       // },
            //     ],
            //   },
            //   {
            //     name: "活动报名",
            //     id: 5,
            //     chlid: "",
            //   },
            // ];
            this.getmunsList();
            this.tabbar = tabbarnew;
            sessionStorage.setItem("page_id", 1);
            // this.isactivebox = sessionStorage.getItem("page_id");
            this.isactivebox = 1;

            sessionStorage.setItem("judgment", val.name);
            this.judgment = val.name;
            break;
          case "announcement":
            this.judgment = "";
            this.isactivebox = 2;
            this.isactive = 2;
            sessionStorage.setItem("page_id", 2);
            sessionStorage.setItem("judgment", val.name);
            break;
          case "Service_resource":
            var tabbarnew = this.defaultTabbar;

            this.getmunsList();
            this.tabbar = tabbarnew;
            this.judgment = "";

            this.isactivebox = 3;
            this.isactive = 3;
            sessionStorage.setItem("page_id", 3);

            sessionStorage.setItem("judgment", val.name);

            break;
          case "Qiye_resource":
            var tabbarnew = this.defaultTabbar;

            this.getmunsList();
            this.tabbar = tabbarnew;
            this.judgment = "";

            this.isactivebox = 4;
            this.isactive = 4;
            sessionStorage.setItem("page_id", 4);
            // window.location.reload()

            sessionStorage.setItem("judgment", val.name);

            break;
          case "Activity":
            this.getmunsList();
            this.judgment = "";
            this.isactive = 5;
            this.isactivebox = 6;
            sessionStorage.setItem("judgment", val.name);
            sessionStorage.setItem("page_id", 6);
            break;
          case "register":
            var tabbarnew = this.defaultTabbar;
            // var tabbarnew = [
            //   {
            //     name: "注册",
            //     id: 0,
            //     chlid: "",
            //   },
            //   {
            //     name: "首页",
            //     id: 1,
            //     chlid: "",
            //   },
            //   {
            //     name: "公告",
            //     id: 2,
            //     chlid: "",
            //   },
            //   {
            //     name: "服务资源",
            //     id: 3,
            //     chlid: "",
            //   },
            //   {
            //     name: "活动报名",
            //     id: 5,
            //     chlid: "",
            //   },
            // ];
            this.getmunsList();
            this.tabbar = tabbarnew;
            this.judgment = val.name;
            sessionStorage.setItem("judgment", val.name);
            this.isactive = 0;
            this.isactivebox = 0;
            break;
          case "Parkresources":
            var tabbarnew = this.defaultTabbar;
            // var tabbarnew = [
            //   {
            //     name: "首页",
            //     id: 1,
            //     chlid: "",
            //   },
            //   {
            //     name: "公告",
            //     id: 2,
            //     chlid: "",
            //   },
            //   {
            //     name: "服务资源",
            //     id: 3,
            //     chlid: "",
            //   },
            //   {
            //     name: "园区资源",
            //     id: 4,
            //     chlid: ''
            //   },
            //   {
            //     name: "活动报名",
            //     id: 5,
            //     chlid: "",
            //   },
            // ];
            this.getmunsList();
            this.isactive = 4;
            this.isactivebox = 5;
            sessionStorage.setItem("page_id", 5);
            this.tabbar = tabbarnew;
            sessionStorage.setItem("judgment", val.name);
            this.judgment = val.name;

            break;
          case "Noticeparticulars":
            this.getmunsList();
            this.judgment = "";
            sessionStorage.setItem("page_id", 2);
            this.isactive = sessionStorage.getItem("page_id");
            this.isactivebox = sessionStorage.getItem("page_id");
            sessionStorage.setItem("judgment", val.name);
            break;
          case "product_details":
            this.getmunsList();
            this.judgment = "";
            this.isactive = 3;
            this.isactivebox = 3;
            sessionStorage.setItem("page_id", 3);
            sessionStorage.setItem("judgment", val.name);
            break;
            break;
          case "enterprise_details":
            this.getmunsList();
            this.judgment = "";
            this.isactive = 4;
            this.isactivebox = 4;
            sessionStorage.setItem("page_id", 4);
            sessionStorage.setItem("judgment", val.name);
            break;
          default:
            break;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Navigation {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;

  .classification {
    width: 20%;
    display: flex;
    justify-content: left;
    height: 40px;

    .classification_center {
      width: 184px;
      padding: 10px;
      display: flex;
      justify-content: left;
      align-items: center;
      background-color: #5d207c;
      cursor: pointer;
    }

    .classification_img {
      width: 14px;
      height: 12px;
    }

    span {
      margin-left: 5px;
      color: #fff;
      font-size: 16px;
    }
  }
}

.navigations {
  width: 58%;
  height: 100%;

  .navigation_list {
    display: flex;
    // align-items: flex-end;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    li {
      width: 100%;
      text-align: center;
    }

    .navigation_linew {
      background-color: #5d207c;
    }

    .navigation_menunew {
      width: 100%;
      // margin-right: 44px;
      line-height: 40px;
      text-align: center;
      box-sizing: content-box;
      cursor: pointer;
      height: 40px;
      background-color: #5d207c;
    }

    .navigation_menu {
      width: 100%;
      // margin-right: 44px;
      line-height: 40px;
      text-align: center;
      box-sizing: content-box;
      height: 40px;
      cursor: pointer;

      span {
        position: relative;
        color: #999999;
        display: flex;
        justify-content: center;
      }

      span:hover {
        color: #5d207c;
      }

      span::before {
        content: "";
        position: absolute;
        height: 3px;

        background: #5d207c;
        width: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        transform: scale(0);
        transform-origin: left;
        transition: transform 0.2s ease-in-out;
        // left: 0;
        bottom: -5px;
      }
    }

    .atcre {
      color: #fff !important;
    }

    .atcre::before {
      transform: scale(1) !important;
    }
  }
}

.submenu {
  position: relative;
  display: flex;
  justify-content: center;

  .submenu_appears {
    background-color: #686c76bf;
    position: absolute;
    // margin-top: 5px;
    display: none;

    li {
      margin-bottom: 10px;

      font-size: 13px;
      text-align: center;
      color: #fff;
      letter-spacing: 2px;

      .yuans_span {
        margin: 0;
        padding: 0;
      }
    }
  }

  .submenu_appear {
    background-color: #686c76bf;
    width: 100%;
    padding: 10px 0 0 0;
    position: absolute;
    text-align: center;
    z-index: 200;
    // overflow-y: auto;
    animation-name: drop-menu;
    animation-duration: 250ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;

    @keyframes drop-menu {
      from {
        height: 0;
      }

      to {
        height: 100px;
      }
    }

    li {
      margin: 0;
      font-size: 14px;
      // width: 80px;
      text-align: center;
      color: #fff;
      margin-bottom: 25px;
      letter-spacing: 2px;
      animation-name: drop-menus;
      animation-duration: 500ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;

      @keyframes drop-menus {
        from {
          height: 0;
        }

        to {
          height: 10px;
        }
      }

      .yuans_span {
      }
    }
  }
}

.yuans_span:hover {
  color: #5d207c;
  background-color: #fff;
  border-radius: 5px;
  // padding: 4px;
  margin-bottom: 10px;
}
</style>